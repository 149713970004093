<span
  *ngFor="let word of phraseWords; let last = last; let first = first"
  [class.missing]="word.dispFormat == 'MISSING'"
  [class.superscript]="word.superscriptSw"
  [class.bold]="word.boldSw"
  [class.italic]="word.italicSw"
  [class.underline]="word.underlineSw"
  [class.diff]="word.markDiffSw"
  [tooltip]="word.formatDescrDiffOnly || word.formatDescr"
  (mouseenter)="updateHighlightedId(word.id)"
  (mouseleave)="updateHighlightedId()"
  [class.diffWordHighlight]="isHighlighted(word.id)"
>

  <grammar-spelling-formatted-word
    *ngIf="!word.chars"
    [wrappedWord]="getWrappedWord(word)"
    (onAddToWhiteList)="addToWhiteList($event)"
    [first]="first"
    >{{ word.text }}</grammar-spelling-formatted-word>

  <grammar-spelling-formatted-word
    *ngIf="word.chars"
    [wrappedWord]="getWrappedWord(word)"
    (onAddToWhiteList)="addToWhiteList($event)"
    [first]="first"
    >
    <span
        *ngFor="let char of word.chars; let i = index; let lastChar = last"
        [class.missing]="char.dispFormat == 'MISSING'"
        [class.superscript]="char.superscriptSw"
        [class.bold]="char.boldSw"
        [class.italic]="char.italicSw"
        [class.underline]="char.underlineSw"
        [class.reference-issue]="getWrappedWord(word) | referenceIssueCheck:i:isReferenceIssueOnIndex"
      >{{ char.text }}</span>
    </grammar-spelling-formatted-word>
</span>
