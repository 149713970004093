<modal-popup
  [flexible]="true"
  [focusOnOpen]="true"
  [isOpen]="true"
  (onClose)="close()"
  [title]="'translate.cat.addSpellingCheckIgnored'"
>
  <div class="container">
    <input-wrap
        label="{{ 'translate.general.spellingErrors' | translate }}"
        cssClass="l-cell-spread"
        >
        <p-listbox
            [options]="spellingErrors"
            [(ngModel)]="spellingWhitelist"
            [checkbox]="true"
            [multiple]="true"
            [listStyle]="{ 'min-width': '400px', 'max-height': '260px' }"
        ></p-listbox>
    </input-wrap>

    <div class="t-panel-cell t-buttonSet l-align-end l-mar-top">
        <button
          [disabled]="loading || spellingWhitelist.length === 0"
          (click)="save()"
          class="t-button-outline"
        >
          {{ "translate.general.save" | translate }}
        </button>
      </div>

    <loader *ngIf="loading"></loader>
  </div>
</modal-popup>
