<modal-popup [flexible]="true" [focusOnOpen]="false" [isOpen]="true" (onClose)="close()" [title]="
    phrase
      ? 'translate.cat.phraseDetailsTitle'
      : 'translate.cat.imageDetailsTitle'
  " [afterTitle]="dirtyBtns">
  <div class="cat-doc-phrase-details-modal-container">
    <div [ngClass]="{ 'cat-doc-phrase-details-container': catMatches.length > 0,
      'cat-doc-phrase-details-single-container': !(catMatches.length > 0) }">
      <div class="l-align-spaceBetween">
        <h3>
          {{
          phrase
          ? ("translate.general.workingPhrase" | translate)
          : ("translate.general.workingImage" | translate)
          }}

          <!--figure type-->
          <!-- <p *ngIf="image" [ngClass]="['status-tag']">
          {{ element.figureTypeDescr }}
        </p> -->
        </h3>

        <!-- status-->
        <div *ngIf="indModeSw && !extSw">
          <p *ngIf="element.statusCode" [ngClass]="['cat-phrase-status', element.statusCode]">
            {{ element.statusDescr }}
          </p>
          <spinner *ngIf="!element.statusCode && element.hasId" class="cat-phrase-status-spinner"></spinner>
        </div>
      </div>

      <div *ngIf="catMatches.length > 0 && getSimilarityEvent()" class="cat-doc-phrase-details-navigation">
        <div class="cat-doc-phrase-details-nav-button-continer">
          <button (click)="prevMatch()" [disabled]="currentIndex === 0"
            class="cat-doc-phrase-details-nav-button cat-doc-phrase-details-nav-button-left">
            <i class="fas fa-arrow-left"></i>
            {{ "translate.cat.prev" | translate }}
          </button>
        </div>

        <div class="cat-doc-phrase-details-current">Current match</div>

        <div class="cat-doc-phrase-details-nav-button-continer">
          <button (click)="nextMatch()" [disabled]="currentIndex === catMatches.length - 1"
            class="cat-doc-phrase-details-nav-button cat-doc-phrase-details-nav-button-right">
            {{ "translate.cat.next" | translate }}
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>

      <!-- phrase text -->
      <div *ngIf="phrase">
        <div *ngIf="phrase.hasId" class="phrase-txt" (mouseenter)="displayPhraseEditBtn = !editModeSw"
          (mouseleave)="displayPhraseEditBtn = false">
          <span *ngIf="phrase.phraseWords.length == 0">
            {{ phrase.phraseText }}
          </span>
          <formatted-phrase *ngIf="phrase.phraseWords.length > 0" [phraseWords]="phrase.phraseWords"
            [grammarSpellingIssues]="phrase.grammarSpellingIssues"
            (onAddToWhitelist)="onAddToWhitelist($event)"
            [referenceIssues]="phrase.referenceIssues"></formatted-phrase>

          <i *ngIf="editable && !editModeSw && inCatEditable" (click)="editModeSw = true"
            class="fas fa-edit icon-edit"></i>
          <i *ngIf="editable && editModeSw" (click)="undoPhraseEdit()" class="fas fa-times icon-edit"></i>
        </div>

        <!-- phrase edit mode -->
        <div *ngIf="editModeSw">
          <textarea *ngIf="catModeSw" class="edit-phrase-area" pInputTextarea [(ngModel)]="editPhraseText"
            autoResize="true"></textarea>

          <div *ngIf="indModeSw" class="fake-textarea edit-phrase-area">
            <formatted-phrase [phraseWords]="editPhraseTextWords"></formatted-phrase>
          </div>

          <div *ngIf="phrase.mergeOptions.length > 0">
            <span *ngIf="phrase.hasId" class="t-footnote">
              {{ "translate.cat.mergeWithNearest" | translate }}
            </span>

            <div class="merge-listbox t-small">
              <p-listbox [options]="phrase.mergeOptions" [(ngModel)]="selectedLines" [checkbox]="true" [multiple]="true"
                dataKey="uniqueId" optionLabel="text" (onClick)="onMergeOptionsClicked($event)"
                [listStyle]="{ 'max-height': '250px' }">
                <ng-template let-item pTemplate="item">
                  <div class="l-flex-column w-full">
                    <div class="l-align-spaceBetween w-full">
                      <p *ngIf="item.phraseWords.length == 0">
                        {{ item.text }}
                      </p>
                      <formatted-phrase *ngIf="item.phraseWords.length > 0"
                        [phraseWords]="item.phraseWords"></formatted-phrase>

                      <p (click)="$event.stopPropagation()" class="t-footnote phrase-id l-align-center">
                        <button class="t-button t-button--pill t-btn-more bg-white"
                          (click)="item.displayWordsSw = !item.displayWordsSw">
                          <i class="fas fa-angle-down"></i>
                        </button>
                        &nbsp; &nbsp;
                        <span> Phrase {{ item.phraseId }}</span>
                      </p>
                    </div>
                    <div (click)="$event.stopPropagation()">
                      <slide-in-out [isOpen]="item.displayWordsSw">
                        <div class="l-flex l-wrap">
                          <div *ngFor="let word of item.phraseWords">
                            <checkbox-wrap class="checkbox" [label]="word.text">
                              <input type="checkbox" [(ngModel)]="word.checked"
                                (ngModelChange)="onMergeWordChange(item)" />
                            </checkbox-wrap>
                            &nbsp;&nbsp;
                          </div>
                        </div>
                      </slide-in-out>
                    </div>
                  </div>
                </ng-template>
              </p-listbox>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="catMatches.length > 0 && getSimilarityEvent()">
        <cat-match-phrase [catMatch]="catMatches[currentIndex]"></cat-match-phrase>
      </div>
    </div>

    <!-- image -->
    <div *ngIf="image" class="t-row--med">
      <!-- <img class="ind-img" [src]="image.imgUrl" /> -->
      <async-img *ngIf="image.imgS3Key" [className]="'inner-img'" [url]="image.imgUrl"></async-img>
    </div>

    <!-- figure type - closed-->
    <div *ngIf="element.figureType" class="cat-phrase-type">
      <span>
        {{ element.figureTypeDescr }}
        <span *ngIf="element.figureSubType" class="sub-type">&middot; {{ element.figureSubType.text }}</span>
      </span>
      <i *ngIf="editable && element.hasId" (click)="opFigureSubTypes.toggle($event)" class="fas fa-edit icon-edit"></i>
    </div>

    <div class="action-btn-container">
      <!-- select type - closed -->
      <div class="cat-phrase-type" (mouseenter)="displayTypeEditBtn = true" (mouseleave)="displayTypeEditBtn = false">
        <span *ngIf="phrase">{{ phrase.phraseContentTypeDesc }}&middot; </span>
        <span *ngIf="element.typeCode && element.subTypeCode">
          {{ element.typeDescr }}
          <span class="sub-type">&middot; {{ element.subTypeDescr }}</span>
        </span>

        <span *ngIf="element.hasId && !(element.typeCode && element.subTypeCode)">
          Select type
        </span>

        <i *ngIf="editable && element.hasId" (click)="opTypes.toggle($event)" class="fas fa-edit icon-edit"></i>
      </div>

      <!--select type - open -->
      <div *ngIf="!element.hasId && (!element.typeCode || !element.subTypeCode)" class="w-full t-row--med">
        <p class="fs-small t-row--small fw-semibold">
          {{ "translate.cat.phraseType" | translate }}
        </p>
        <ng-container *ngTemplateOutlet="typesTree"></ng-container>
      </div>

      <!--status button-->
      <p-selectButton *ngIf="indModeSw && element.hasId && editable" class="cat-phrase-status"
        [options]="phraseStatusOptions" [(ngModel)]="element.userStatus" (onChange)="onElementStatusChange()"
        [disabled]="isDirty()"></p-selectButton>
    </div>

    <!--cat entry status-->

    <div>
      <div *ngIf="element.catEntryStatus" class="cat-entry-status cat-phrase-type">
        <span>
          {{ element.catEntryStatus.text }}
        </span>
        <i *ngIf="editable" (click)="opCatEntryStatus.toggle($event)" class="fas fa-edit icon-edit"></i>
      </div>
    </div>

    <div [ngClass]="{
      'cat-doc-phrase-details-scrollable-grid': true,
      'cat-doc-phrase-details-container': catMatches.length > 0,
      'cat-doc-phrase-details-single-container': !(catMatches.length > 0)}">

      <ng-container *ngIf="phrase && !phrase.isRefSw && phrase.references.length > 0">
        <p class="cat-doc-phrase-details-ref-title">{{ "translate.rev.reference" | translate }}:</p>
        <ng-container *ngIf="catMatches.length > 0">
          <p class="cat-doc-phrase-details-ref-title">{{ "translate.rev.reference" | translate }}:</p>
        </ng-container>
        <ng-container *ngFor="let ref of phrase.references">
          <div class="cat-doc-phrase-ref-container">
            <cat-phrase-ref class="ref" [ref]="ref" [mainPhrase]="phrase" (onDeleteRef)="onDeleteRef(ref)"
              [editable]="editable && inCatEditable" [allowEdit]="catModeSw"></cat-phrase-ref>
          </div>

          <ng-container *ngIf="catMatches.length > 0 && getSimilarityEvent()">
            <div class="issues-column">
              <ng-container *ngIf="getReferenceIssue(catMatches[currentIndex].catPhrase.phraseId, ref.refPhraseId)?.refFinding === 'ADDED_REFERENCE'">
                <div class="ref ref-match-container missing-reference" style="direction: rtl; padding-right: 0.5rem;">
                  <div class="ref-similarity-code">
                    <p class="extra">
                      {{ "translate.rev.refAdded" | translate }}
                    </p>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!(getReferenceIssue(catMatches[currentIndex].catPhrase.phraseId, ref.refPhraseId)?.refFinding === 'ADDED_REFERENCE')">
                <cat-match-phrase-ref class="ref" [ref]="ref" [mainPhrase]="catMatches[currentIndex].catPhrase"
                  [referenceIssue]="getReferenceIssue(catMatches[currentIndex].catPhrase.phraseId, ref.refPhraseId)">
                </cat-match-phrase-ref>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="catMatches.length > 0 && getSimilarityEvent()">
          <ng-container *ngFor="let issue of getMissingReferenceIssue(catMatches[currentIndex].catPhrase.phraseId)">
            <div class="reference-column">
              <div class="ref missing-reference"></div>
            </div>
            <div class="issues-column">
              <div class="cat-doc-phrase-details-ref-container">
                <div class="ref-match-container">
                  <div>
                    <p class="text">
                      {{ getCatPhraseReference(issue.catRefPhraseId)?.refPhraseText }}
                    </p>
                    <p class="ref-id">
                      {{ "translate.cat.phraseId" | translate }}: {{ issue.catRefPhraseId }}
                    </p>
                  </div>
                  <div class="ref-similarity-code">
                    <p class="missing">
                      {{ "translate.rev.refMissing" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <cat-doc-ref-options *ngIf="displayRefOptions" (onRefAdd)="onPhraseRefAdd($event)"
      (onRefRemove)="onPhraseRefRemove($event)"></cat-doc-ref-options>

    <!--referenced by -->
    <div *ngIf="phrase && phrase.referencedBy.length > 0 && phrase.isRefSw" class="reference t-row--med">
      <p class="title">{{ "translate.rev.referencedBy" | translate }}:</p>
      <div *ngFor="let ref of phrase.referencedBy" class="ref-container text l-flex-column">
        <p *ngIf="ref.phraseDispWords.length == 0">
          {{ ref.refPhraseText }}
        </p>
        <formatted-phrase *ngIf="ref.phraseDispWords.length > 0" [phraseWords]="ref.phraseDispWords"
          class="t-row--small"></formatted-phrase>
        <div>
          <span class="footnote id">{{ "translate.cat.phraseId" | translate }}:
            {{ ref.phraseId }}</span>
        </div>
      </div>
    </div>

    <div class="l-align-spaceBetween" style="margin-top: 0.5rem;">
      <a *ngIf="editable && inCatEditable" (click)="addReference()" class="add-link">
        <i class="fas fa-plus"></i>
        &nbsp;
        {{ "translate.cat.addReference" | translate }}
      </a>
      <a *ngIf="displayRefOptions" (click)="displayRefOptions = false" class="t-small">
        {{ "translate.general.close" | translate }}
      </a>
    </div>

    <cat-doc-assoc-text-options *ngIf="displayAssocTextOptions" (onAssocTextAdd)="onAssociatedTextAdd($event)"
      (onAssocTextRemove)="onAssociatedTextRemove($event)"></cat-doc-assoc-text-options>

    <div class="l-align-spaceBetween" style="margin-top: 0.5rem;">
      <a *ngIf="editable && inCatEditable" (click)="addAssociatedText()" class="add-link">
        <i class="fas fa-plus"></i>
        &nbsp;
        {{ "translate.cat.addAssociatedText" | translate }}
      </a>
      <a *ngIf="displayAssocTextOptions" (click)="displayAssocTextOptions = false" class="t-small">
        {{ "translate.general.close" | translate }}
      </a>
    </div>

    <!--ext ref-->
    <div *ngIf="phrase && phrase.extRefs.length > 0" class="ext-ref t-row--med">
      <p class="title">{{ "translate.cat.extRefs" | translate }}:</p>
      <div *ngFor="let ref of phrase.extRefs">
        <div class="ref-container text l-flex-column">
          <formatted-phrase [phraseWords]="ref.extPhraseDispWords"></formatted-phrase>

          <div class="actions">
            <a (click)="removeExtRef(ref)" class="t-small">Remove</a>
          </div>
        </div>

        <p class="footnote l-ta-end">
          {{ ref.extDocName }} ({{ ref.extCatDescr }}) &nbsp;
        </p>
      </div>
    </div>

    <!-- instructions-->
    <div>
      <h3 *ngIf="element.addInstr && element.addInstr.length > 0">
        {{ "translate.rev.instructions" | translate }}
      </h3>

      <cat-add-instr *ngFor="let instr of element.addInstr" [instr]="instr" (onDelete)="onDeleteInstr(instr)"
        [editable]="editable"></cat-add-instr>

      <a *ngIf="editable" (click)="addInstr()" class="add-link">
        <i class="fas fa-plus"></i>
        &nbsp;
        {{ "translate.cat.addMoreInstr" | translate }}
      </a>
    </div>

    <!--docs-->
    <div *ngIf="displayEntryDocs && element.docs && element.docs.length > 0" class="docs-container t-small">
      <a (click)="displayDocsSw = !displayDocsSw">
        {{ "translate.cat.matchSourceDocs" | translate }}
      </a>
      <slide-in-out [isOpen]="displayDocsSw">
        <div class="docs-tbl">
          <cat-entry-docs [docs]="element.docs"></cat-entry-docs>
        </div>
      </slide-in-out>
    </div>

    <!-- matches -->
    <ng-container *ngIf="!getSimilarityEvent()">
      <ng-container *ngTemplateOutlet="matchesTemplate"></ng-container>
    </ng-container>

    <div class="l-align-spaceBetween l-mar-top">
      <p [style.visibility]="element.hasId ? 'visible' : 'collapsed'" class="footnote id">
        <span *ngIf="phrase"> {{ "translate.cat.phraseId" | translate }}</span>
        <span *ngIf="image"> {{ "translate.cat.imageId" | translate }}</span>
        <span>: {{ element.elementId }}</span>
      </p>

      <!-- save and delete buttons -->
      <div>
        <button *ngIf="element.hasId && editable" class="t-button-outline t-button-warn" [disabled]="loading"
          (click)="onDeleteClicked()">
          <span *ngIf="phrase">
            {{ "translate.cat.deletePhrase" | translate }}</span>
          <span *ngIf="image">
            {{ "translate.cat.deleteImage" | translate }}</span>
        </button>

        <button *ngIf="!element.hasId" class="t-button" [disabled]="loading || !validateBeforeSave()"
          (click)="onSaveClicked()">
          {{ "translate.general.save" | translate }}
        </button>
      </div>
    </div>
    `
    <loader *ngIf="loading"></loader>
  </div>
</modal-popup>

<ng-template #matchesTemplate>
  <ng-container *ngIf="notPotentialNew">
    <div class="template-container" *ngFor="let match of element.catMatches">
      <cat-match [match]="match" [referenceIssues]="phrase.referenceIssues"> </cat-match>
    </div>
  </ng-container>
</ng-template>

<p-overlayPanel #opTypes appendTo="body" baseZIndex="999999">
  <ng-template pTemplate>
    <ng-container *ngTemplateOutlet="typesTree"></ng-container>
  </ng-template>
</p-overlayPanel>

<ng-template #typesTree>
  <p-tree class="fs-small" [value]="catPhraseTypesTree" [(selection)]="selectedPhraseType" scrollHeight="250px"
    selectionMode="single" filter="true" (onNodeSelect)="onTypeSelected($event)"></p-tree>
</ng-template>

<!-- edit phrase buttons -->
<ng-template #dirtyBtns>
  <div *ngIf="element.hasId && (isDirty() || hasWhitelistedWords())" class="t-small">
    <button class="t-button t-button-warn" [disabled]="loading || !validateBeforeSave()" (click)="onSaveClicked()">
      {{ "translate.general.saveChanges" | translate }}
    </button>
    &nbsp;
    <button class="t-button-outline t-button-warn" [disabled]="loading" (click)="discardChanges()">
      {{ "translate.general.discardChanges" | translate }}
    </button>
  </div>
</ng-template>

<p-overlayPanel #opCatEntryStatus appendTo="body" baseZIndex="100">
  <ng-template pTemplate>
    <ng-container *ngTemplateOutlet="catEntryStatusTmp"></ng-container>
  </ng-template>
</p-overlayPanel>

<ng-template #catEntryStatusTmp>
  <p-dropdown [options]="catEntryStatusList" optionLabel="label" [(ngModel)]="element.catEntryStatus"
    (onChange)="opCatEntryStatus.hide()">
  </p-dropdown>
</ng-template>

<p-overlayPanel #opFigureSubTypes appendTo="body" baseZIndex="100">
  <ng-template pTemplate>
    <p-dropdown [options]="figureSubTypeList" optionLabel="label" [(ngModel)]="element.figureSubType"
      class="l-cell-spread-x" [placeholder]="'translate.general.select' | translate" [autofocus]="true"
      (onChange)="opFigureSubTypes.hide()">
    </p-dropdown>
  </ng-template>
</p-overlayPanel>