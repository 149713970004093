import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CatDocStatusEnum,
  CatDoc,
  Catalog,
  CatPhraseSubType,
  IndPhrase,
  IndPhraseSearchParams,
  CatPhraseStatusEnum,
  BoundingBox,
  IndImage,
  IndElement,
  Card,
  CardSearchParams,
  CatDocStateTagEnum,
  ExtRef,
} from '../shared/models/cat.model';
import { DocViewerComponent } from '../shared/components/doc-viewer/doc-viewer.component';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from '../shared/redux/store';
import { CatService } from '../shared/services/cat.service';
import { IntegrationService } from '../shared/services/integration.service';
import {
  ActivatedRoute,
  Params,
  PRIMARY_OUTLET,
  Router,
} from '@angular/router';
import { SharedService } from '../shared/services/shared.service';
import * as Chart from 'chart.js';
import { keyCodes } from '../shared/models/key-codes.model';
import { TreeNode } from 'primeng/api';
import {
  AnnotationElement,
  DataElement,
  ProcessRunStatusEnum,
} from '../shared/models/common';
import { Unsubscribe } from 'redux';
import { AuthService } from '../shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { PdfViewerComponent } from '../shared/components/pdf-viewer/pdf-viewer.component';
import { CatDocPhraseListComponent } from './cat-doc-phrase-list/cat-doc-phrase-list.component';
import { forkJoin, Subscribable, Subscription } from 'rxjs';
import { CatPhraseBulkEditComponent } from './cat-phrase-bulk-edit/cat-phrase-bulk-edit.component';
import { Location } from '@angular/common';
import { TabIdList } from '../shared/models/tab.model';
import { CatDocCardsComponent } from './cat-doc-cards/cat-doc-cards.component';

@Component({
  selector: 'cat-doc',
  templateUrl: './cat-doc.component.html',
  styleUrls: ['./cat-doc.component.scss'],
})
export class CatDocComponent implements OnInit {
  @Input() stateTag: CatDocStateTagEnum = CatDocStateTagEnum.PRIME;
  @Input() srcPhraseId: number;
  @Input() extCatVersionId: number;

  @Output() onAddExtRef = new EventEmitter();
  @Output() onGoBack = new EventEmitter();

  @ViewChild('docViewer') docViewer: PdfViewerComponent;
  @ViewChild('phraseList') phraseList: CatDocPhraseListComponent;
  @ViewChild('phraseBulkEdit') phraseBulkEditCmp: CatPhraseBulkEditComponent;
  @ViewChild('catDocCards') catDocCardsCmp: CatDocCardsComponent;

  loading: boolean;
  filterPhrase: string;
  selectedTypeNodes: TreeNode[];
  filterSubTypes: string[];
  filterMatchStatus: DataElement;
  filterPhraseStatus: DataElement;
  filterContentType: DataElement;
  filterMatchType: DataElement;
  canvasStatus: any;
  ctxStatus: any;
  phraseDetailsOpenSw: boolean;
  un: Unsubscribe;
  initAnnotationsloadedPageNum: number;
  docFrameLoadedPageNum: number;
  phraseListLoadedPageNum: number;
  toggleAllSw: boolean;
  toggleAllImgSw: boolean;
  boundingBoxOpenSw: boolean;
  curBoundingBox: BoundingBox;
  annotateHtmlDirtySw: boolean;
  annotateProcessStatusTimer: NodeJS.Timeout;
  getProcessStatusIntervalSeconds: number = 3;
  editPhraseTypeOpenSw: boolean;
  panelResizing: boolean;
  toggleImageAnnotationsSw: boolean;
  docCardsOpenSw: boolean;
  addExtRefLoading: boolean;
  tabIdList: TabIdList;
  docFrameLoaded: boolean = false;
  editPhraseOrderOpenSw: boolean;
  docSpellcheckWhitelistingOpenSw: boolean;
  downloadingZip: boolean;
  sub: Subscription;

  mode: 'lc_ac' | 'lc_bc' | null = null;

  get extSw(): boolean {
    return this.stateTag == CatDocStateTagEnum.PRIME && this.curCat.extSw;
  }

  get showMatchSw(): boolean {
    return !this.extSw && this.stateTag != CatDocStateTagEnum.SEC;
  }

  get lcSw(): boolean {
    if (!this.mode) return false;
    return this.mode.startsWith('lc');
  }

  get doc(): CatDoc {
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.curCatDoc
      : this.ngRedux.getState().curCatDocStateSec.curCatDoc;
  }

  get curCat(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  get matchSimilarityCodes(): DataElement[] {
    return this.ngRedux.getState().catMatchSimilarityCodes;
  }

  get catPhraseStatusList(): DataElement[] {
    return this.ngRedux.getState().catPhraseStatusList;
  }

  get contentTypeList(): DataElement[] {
    return this.ngRedux.getState().contentTypeList;
  }

  get matchTypeList(): DataElement[] {
    return this.ngRedux.getState().indRuleSimilarityEventList;
  }

  get catPhraseTypes(): TreeNode[] {
    if (!this.ngRedux.getState().catPhraseTypeModel) return [];
    return this.ngRedux.getState().catPhraseTypeModel.treeTypes;
  }

  get displayedPhrases(): IndPhrase[] {
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.indDocPhrases
      : this.ngRedux.getState().curCatDocStateSec.indDocPhrases;
  }

  get displayedImages(): IndImage[] {
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.indDocImages
      : this.ngRedux.getState().curCatDocStateSec.indDocImages;
  }

  get catDocBoundingBoxes(): BoundingBox[] {
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.catDocBoundingBoxes
      : this.ngRedux.getState().curCatDocStateSec.catDocBoundingBoxes;
  }

  get allowAddToCatalog(): boolean {
    return this.doc.statusCode == CatDocStatusEnum.DRAFT;
  }

  get allowDetach(): boolean {
    return this.doc.statusCode == CatDocStatusEnum.ADDED_TO_CATALOG;
  }

  get allowReopen(): boolean {
    return (
      this.doc.statusCode == CatDocStatusEnum.DETACHED ||
      this.doc.statusCode == CatDocStatusEnum.INACTIVE
    );
  }

  get allowInactivate(): boolean {
    return (
      this.doc.statusCode == CatDocStatusEnum.DETACHED ||
      this.doc.statusCode == CatDocStatusEnum.DRAFT
    );
  }

  get curPage(): number {
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.curCatDocPageNum
      : this.ngRedux.getState().curCatDocStateSec.curCatDocPageNum;
  }

  get docCards(): Card[] {
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.catDocCards
      : this.ngRedux.getState().curCatDocStateSec.catDocCards;
  }

  get enableAddPhrase(): boolean {
    if (
      this.stateTag == CatDocStateTagEnum.PRIME &&
      this.ngRedux.getState().curCatDocStatePrime.indDocPhrases.length == 0
    )
      return false;
    else return true;
  }

  get displayClearFilter(): boolean {
    return (
      this.filterPhrase != null ||
      this.filterSubTypes != null ||
      this.filterMatchStatus != null ||
      this.filterPhraseStatus != null ||
      this.filterContentType != null ||
      this.filterMatchType != null
    );
  }

  get selectedPhrases(): IndPhrase[] {
    return this.displayedPhrases.filter((r) => r.checkedSw);
  }

  get selectedElements(): IndElement[] {
    let elements: IndElement[] = [
      ...this.displayedPhrases,
      ...this.displayedImages,
    ];
    return elements.filter((r) => r.checkedSw);
  }

  get displayToggleBoundingBoxes(): boolean {
    return this.catDocBoundingBoxes.length > 0;
  }

  get displayCatInfo(): boolean {
    return this.stateTag == CatDocStateTagEnum.PRIME && !this.mode;
  }

  get displayDocActions(): boolean {
    return this.stateTag == CatDocStateTagEnum.PRIME && !this.mode;
  }

  get displayDrawMode(): boolean {
    return this.stateTag == CatDocStateTagEnum.PRIME;
  }

  get editable(): boolean {
    return (
      this.doc.statusCode == CatDocStatusEnum.DRAFT &&
      !this.mode &&
      this.stateTag == CatDocStateTagEnum.PRIME &&
      !this.curCat.extSw
    );
  }

  get allowAddExtRef(): boolean {
    return this.stateTag == CatDocStateTagEnum.SEC;
  }

  get viewerHeightOffset(): string {
    if (this.stateTag == CatDocStateTagEnum.PRIME) {
      return 'calc(100vh - 100px)';
    } else {
      return 'calc(100vh - 200px)';
    }
  }

  get pageLoading(): boolean {
    return this.ngRedux.getState().docPageLoading;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private integrationService: IntegrationService,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthService,
    private translateService: TranslateService,
    private location: Location,
    private route: ActivatedRoute
  ) {
    this.tabIdList = this.ngRedux.getState().ui_tabIdList;
    this.un = this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.UPD_CAT_DOC_STATS:
          this.drawStatusChart();
          break;
        case actionsList.GET_IND_DOC_PHRASES:
          if (appState._currentActionCatDocStateTag != CatDocStateTagEnum.PRIME)
            return;

          this.phraseListLoadedPageNum = this.curPage;
          if (
            this.initAnnotationsloadedPageNum !== this.curPage &&
            this.docFrameLoadedPageNum == this.curPage
          ) {
            this.addAllAnnotationsToDoc();

            if (this.lcSw) return;
            setTimeout(() => {
              this.togglePendingAnnotations();
            }, 500);
          }
          break;
        case actionsList.REANNOTATE_CAT_DOC_REQ:
          if (appState._currentActionData == true) {
            //this.createAnnotateHtmlReq();
            this.docViewer.goToPage(this.curPage);
          }
          break;
      }
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.mode = params['mode'];
    });

    this.resetFilterParams();
    this.ngRedux.dispatch({
      type: actionsList.SELECT_CAT_DOC_PAGE_NUM,
      data: 1,
      catDocSTateTag: this.stateTag,
    });
    this.getDocCards();
    this.getDocObjects();
  }

  ngOnDestroy(): void {
    if (this.un) this.un();
    this.sub?.unsubscribe();
    if (this.annotateProcessStatusTimer)
      clearInterval(this.annotateProcessStatusTimer);
  }

  getDocObjects() {
    this.catService.getDocObjects(this.doc.docId, 'IND').subscribe();
  }

  getDocCards() {
    if (!this.doc) return;

    let sp = new CardSearchParams();
    sp.docId = this.doc.docId;
    sp.catVersionId =
      this.stateTag == CatDocStateTagEnum.SEC ? this.extCatVersionId : null;
    this.catService.getCards(sp).subscribe((data) => {
      let cards = [];
      if (this.lcSw) {
        cards = data.filter((c) => c.type.id == 'LC');
      } else {
        cards = data.filter((c) => c.type.id != 'LC');
      }

      this.ngRedux.dispatch({
        type: actionsList.GET_CAT_DOC_CARDS,
        data: cards,
        catDocSTateTag: this.stateTag,
      });

      this.catDocCardsCmp.initTabs();

      let bbKeys = [];
      cards.forEach((c) =>
        c.cardDocs.forEach((d) =>
          d.boundingBoxes.forEach((bb) => bbKeys.push(bb.s3Key))
        )
      );
      this.catService.getS3Objects(bbKeys).subscribe();
    });
  }

  drawStatusChart() {
    this.canvasStatus = document.getElementById('chart-status-cnv');
    if (!this.canvasStatus) return;

    this.ctxStatus = this.canvasStatus.getContext('2d');

    let doneCnt = this.doc.approvedCnt + this.doc.rejectedCnt + this.doc.approvedVariantCnt;
    let pendingCnt = this.doc.pendingCnt;

    let myChart = new Chart(this.ctxStatus, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [doneCnt, pendingCnt],
            backgroundColor: ['#0978A2', '#fafafa'],
            hoverBackgroundColor: ['#0978A2', '#fafafa'],
          },
        ],
        labels: ['Done', 'Pending'],
      },
      options: {
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItems, data) => {
              return (
                data.labels[tooltipItems.index] +
                ' ' +
                data.datasets[0].data[tooltipItems.index]
              );
            },
          },
        },
        cutoutPercentage: 70,
        legend: {
          display: false,
        },
        elements: {
          center: {
            text: Math.round(this.doc.progressPct * 100) + '%',
            color: '#0978A2',
            sidePadding: 40,
          },
        },
      },
    });
  }

  goBack() {
    this.ngRedux.dispatch({
      type: actionsList.CLOSE_CAT_DOC,
      catDocSTateTag: this.stateTag,
    });

    if (this.stateTag == CatDocStateTagEnum.PRIME) {
      this.location.back();
    } else {
      this.onGoBack.emit();
    }
    //this.router.navigate([this.sharedService.routeEnum.URL_CATALOG_DOCS]);
  }

  onFilterKeyPress(e: any) {
    if (e.keyCode == keyCodes.enter) {
      this.filterPhrases();
    }
  }

  resetFilterParams() {
    this.filterPhrase = null;
    this.filterSubTypes = null;
    this.filterMatchStatus = null;
    this.filterPhraseStatus = null;
    this.filterContentType = null;
    this.filterMatchType = null;
    this.filterSubTypes = null;
    this.selectedTypeNodes = null;
    if (this.stateTag == CatDocStateTagEnum.PRIME) {
      this.ngRedux.getState().curCatDocStatePrime.catPhrasesSearchParams =
        this.getSearchParams();
    } else {
      this.ngRedux.getState().curCatDocStateSec.catPhrasesSearchParams =
        this.getSearchParams();
    }
  }

  clearFilter() {
    this.resetFilterParams();

    this.ngRedux.dispatch({
      type: actionsList.SELECT_CAT_DOC_PAGE_NUM,
      data: this.curPage,
      catDocSTateTag: this.stateTag,
    });
  }

  getSearchParams(): IndPhraseSearchParams {
    let params = new IndPhraseSearchParams();
    params.phrase = this.filterPhrase;
    params.matchStatus = this.filterMatchStatus;
    params.phraseStatus = this.filterPhraseStatus;
    params.contentType = this.filterContentType;
    params.matchType = this.filterMatchType;
    params.subTypeList = this.filterSubTypes;
    return params;
  }

  filterPhrases() {
    this.ngRedux.dispatch({
      type: actionsList.FILTER_CAT_DOC_PHRASES,
      data: this.getSearchParams(),
      catDocSTateTag: this.stateTag,
    });
  }

  onAddToCatalog() {
    if (this.extSw) {
      this.loading = true;
      this.updateBulkStatus([], CatPhraseStatusEnum.APPROVE, true, true);
    } else {
      this.addToCatalog();
    }
  }

  addToCatalog() {
    this.loading = true;

    this.catService.addIndDocToCat(this.doc).subscribe(
      (data) => {
        this.drawStatusChart();
        this.integrationService.addVariantsToLibrary(this.doc.catVersionId, this.doc.docId).subscribe(
          (data) => {
            this.loading = false;
          },
          (err) => (this.loading = false)
        )
      },
      (err) => (this.loading = false)
    );
  }

  detach() {
    if (this.doc.attachedCardSw) {
      let validationStr = this.translateService.instant(
        'translate.cat.detachWithCardWarning'
      );

      this.sharedService.emitPopupchReceivedEvent({
        title: 'translate.validation.confirm',
        icon: 'exclamation-triangle',
        subTitle: validationStr,
        keep: false,
        approve: () => {
          this.updateDocStatus(CatDocStatusEnum.DETACHED);
        },
      });
    } else {
      this.updateDocStatus(CatDocStatusEnum.DETACHED);
    }
  }

  reopen() {
    this.updateDocStatus(CatDocStatusEnum.DRAFT);
  }

  inactivateDoc() {
    this.updateDocStatus(CatDocStatusEnum.INACTIVE);
  }

  updateDocStatus(statusCode: string) {
    this.loading = true;
    this.catService.updIndDoc(this.doc, statusCode).subscribe(
      (data) => {
        this.drawStatusChart();
        this.loading = false;
      },
      (err) => (this.loading = false)
    );
  }

  onTypesPanelHide() {
    if (!this.selectedTypeNodes || this.selectedTypeNodes.length == 0) {
      this.filterSubTypes = null;
    } else {
      this.filterSubTypes = this.selectedTypeNodes
        .filter((n) => n.data['treeLevel'] == 1)
        .map((n) => n.key);
    }

    let prevSearchValues =
      this.ngRedux.getState().curCatDocStatePrime.catPhrasesSearchParams == null
        ? []
        : this.ngRedux.getState().curCatDocStatePrime.catPhrasesSearchParams
            .subTypeList ?? [];
    let curSearchValues = this.filterSubTypes ?? [];
    if (curSearchValues.join(',') !== prevSearchValues.join(','))
      this.filterPhrases();
  }

  onFieldValueChanged(e: any) {
    (e.originalEvent as MouseEvent).stopPropagation();
    this.filterPhrases();
  }

  approveAllPages() {
    this.updateBulkStatus(
      [...this.displayedPhrases, ...this.displayedImages],
      CatPhraseStatusEnum.APPROVE,
      true
    );
  }

  approveSelected() {
    this.updateBulkStatus(this.selectedElements, CatPhraseStatusEnum.APPROVE);
  }

  rejectSelected() {
    this.updateBulkStatus(this.selectedElements, CatPhraseStatusEnum.REJECT);
  }

  updateBulkStatus(
    elements: IndElement[],
    status: CatPhraseStatusEnum,
    allDoc: boolean = false,
    autoAddToCat: boolean = false
  ) {
    this.loading = true;
    this.catService
      .updIndPhraseStatus(this.doc, elements, status, allDoc, this.curPage)
      .subscribe(
        (data) => {
          elements.forEach((r) => {
            let updPhrase = data.find((d) => d.elementId == r.elementId);
            r.statusCode = updPhrase.statusCode;
            r.statusDescr = updPhrase.statusDescr;
            r.userStatus = this.sharedService
              .getCatPhraseUpdStatusOptions()
              .find((s) => s.id == r.statusCode);
            this.onToggleElementView(r);
          });

          elements.forEach((r) => {
            r.checkedSw = false;
          });
          this.toggleAllSw = false;
          this.loading = false;

          if (autoAddToCat) {
            this.addToCatalog();
          }
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  addPhrase() {
    let newPhrase = new IndPhrase();
    newPhrase.docId = this.doc.docId;
    newPhrase.catId = this.curCat.catId;
    newPhrase.mergeOptions =
      this.ngRedux.getState().curCatDocStatePrime.catDocPageLines;
    newPhrase.mergeOptions.map((m) => (m.inUseSw = false));
    this.ngRedux.dispatch({
      type: actionsList.SELECT_CAT_ELEMENT,
      data: newPhrase,
    });
    this.phraseDetailsOpenSw = true;
  }

  onPhraseSave() {
    this.phraseDetailsOpenSw = false;
    this.ngRedux.dispatch({
      type: actionsList.SELECT_CAT_DOC_PAGE_NUM,
      data: this.curPage,
      catDocSTateTag: this.stateTag,
    });

    //this.createAnnotateHtmlReq();
  }

  onPageChanged(pageNum: number) {
    this.resetFilterParams();

    this.ngRedux.dispatch({
      type: actionsList.SELECT_CAT_DOC_PAGE_NUM,
      data: pageNum,
      catDocSTateTag: this.stateTag,
    });
    this.toggleAllSw = false;
  }

  onToggleAnnotations(viewSw: boolean) {
    let elements: IndElement[] = [
      ...this.displayedPhrases,
      // ...this.displayedImages,
    ];
    elements.forEach((r) => {
      if (r.pageNum == this.curPage) {
        r.viewSw = viewSw;
        this.onToggleElementView(r);
      }
    });
  }

  onToggleImageAnnotations(viewSw: boolean) {
    this.toggleImageAnnotationsSw = viewSw;
    let elements: IndElement[] = [
      //...this.displayedPhrases,
      ...this.displayedImages,
    ];
    elements.forEach((r) => {
      if (r.pageNum == this.curPage) {
        r.viewSw = viewSw;
        this.onToggleElementView(r);
      }
    });
  }

  onToggleElementView(element: IndElement, changePageSw: boolean = true) {
    let toggleElements = () => {
      if (element.viewSw) {
        this.showAnnotation(element);
      } else {
        this.hideAnnotation(element);
      }
    };

    if (element.pageNum !== this.curPage && changePageSw) {
      this.ngRedux.dispatch({
        type: actionsList.SELECT_CAT_DOC_PAGE_FROM_SEARCH,
        data: element.pageNum,
        catDocSTateTag: CatDocStateTagEnum.PRIME,
      });
      this.docViewer.goToPage(element.pageNum);
    } else {
      toggleElements();
    }
  }

  hideAnnotation(element: IndElement) {
    element.annId = element.elementId;
    this.docViewer.hideAnnotation(element, false);
  }

  showAnnotation(element: IndElement, visible: boolean = true) {
    if (element.bgImgSw || element.pageNum != this.docViewer.displayedPage)
      return;

    switch (element.statusCode) {
      case CatPhraseStatusEnum.APPROVE:
        element.annColor = '#30bb30';
        break;
      case CatPhraseStatusEnum.APPROVE_VAR:
        element.annColor = '#30bb30';
        break;
      case CatPhraseStatusEnum.REJECT:
        element.annColor = '#ff2229';
        break;
      case CatPhraseStatusEnum.PENDING:
        element.annColor = '#d86c06';
        break;
      default:
        element.annColor = '#0978A2';
        break;
    }
    element.annId = element.elementId;
    this.docViewer.showAnnotation(element, visible);
  }

  onDocIframeLoaded() {
    this.docFrameLoadedPageNum = this.curPage;

    if (
      this.initAnnotationsloadedPageNum !== this.curPage &&
      this.phraseListLoadedPageNum == this.curPage
    ) {
      this.addAllAnnotationsToDoc();
      if (this.lcSw) return;

      setTimeout(() => {
        this.togglePendingAnnotations();
      }, 500);
    }

    this.docFrameLoaded = true;
  }

  addAllAnnotationsToDoc() {
    let elements: IndElement[] = [
      ...this.displayedPhrases,
      ...this.displayedImages,
    ];
    elements.forEach((r) => {
      this.showAnnotation(r, false);
    });
  }

  togglePendingAnnotations() {
    let elements: IndElement[] = [
      ...this.displayedPhrases,
      //...this.displayedImages,
    ];
    this.initAnnotationsloadedPageNum = this.curPage;
    elements.forEach((r) => {
      r.viewSw = r.statusCode == CatPhraseStatusEnum.PENDING;
      this.onToggleElementView(r, false);
    });
  }

  onToggleSelectAll() {
    this.displayedPhrases.forEach((p) => (p.checkedSw = this.toggleAllSw));
  }

  onToggleSelectAllImg() {
    this.displayedImages.forEach((p) => (p.checkedSw = this.toggleAllImgSw));
  }

  onBoundingBoxDraw(box: BoundingBox) {
    this.curBoundingBox = box;
    this.boundingBoxOpenSw = true;
  }

  onBoundingBoxDiscard() {
    this.docViewer.removeBoundingBoxes();
    this.boundingBoxOpenSw = false;
  }

  onBoundingBoxSave(box: BoundingBox) {
    this.docViewer.removeBoundingBoxes();
    this.boundingBoxOpenSw = false;
    let ob = this.catService.createBoundingBoxImage(box);

    ob.subscribe({
      next: () => {
        this.getDocCards();
        if (this.lcSw)
          this.ngRedux.dispatch({
            type: actionsList.RELOAD_CUR_LC_REQ,
          });
      },
    });

    this.getDocCards();

    this.ngRedux.dispatch({
      type: actionsList.SELECT_CAT_DOC_PAGE_NUM,
      data: this.curPage,
      catDocSTateTag: this.stateTag,
    });

    if (this.lcSw)
      this.ngRedux.dispatch({
        type: actionsList.RELOAD_CUR_LC_REQ,
        data: ob,
      });
  }

  onImageSave(imageId: number) {
    this.docViewer.removeBoundingBoxes();
    this.boundingBoxOpenSw = false;
    this.catService.createIndImage(this.doc.docId, imageId).subscribe({
      next: (imgUrl) => {
        this.resetFilterParams();
        this.ngRedux.dispatch({
          type: actionsList.SELECT_CAT_DOC_PAGE_NUM,
          data: this.curPage,
          catDocSTateTag: this.stateTag,
        });
        this.getDocObjects();
      },
    });
  }

  onBoundingBoxDelete() {
    this.docViewer.removeBoundingBox(this.curBoundingBox);
    this.boundingBoxOpenSw = false;
    this.getDocCards();
  }

  onToggleBoundingBoxes(viewSw: boolean) {
    this.catDocBoundingBoxes.forEach((b) => {
      if (viewSw) {
        this.docViewer.displayBoundingBox(b);
      } else {
        this.docViewer.removeBoundingBox(b);
      }
    });
  }

  onBoundingBoxClicked(elementId: String) {
    this.curBoundingBox = this.catDocBoundingBoxes.find(
      (b) => b.elementId == elementId
    );
    if (this.curBoundingBox) {
      // this.boundingBoxOpenSw = true;
      this.docCardsOpenSw = true;
    }
  }

  onAnnMouseEnter(ann: AnnotationElement) {
    let element: IndElement;
    if (ann.annIdPrefix == 'img') {
      // if (!this.toggleImageAnnotationsSw) {
      //   return;
      // }
      element = this.ngRedux
        .getState()
        .curCatDocStatePrime.indDocImages.find((r) => r.elementId == ann.annId);
    } else {
      element = this.ngRedux
        .getState()
        .curCatDocStatePrime.indDocPhrases.find(
          (r) => r.elementId == ann.annId
        );
    }

    if (element) this.phraseList.onMouseEnter(element);
  }

  onAnnMouseLeave(ann: AnnotationElement) {
    let element: IndElement;
    if (ann.annIdPrefix == 'img') {
      element = this.ngRedux
        .getState()
        .curCatDocStatePrime.indDocImages.find((r) => r.elementId == ann.annId);
    } else {
      element = this.ngRedux
        .getState()
        .curCatDocStatePrime.indDocPhrases.find(
          (r) => r.elementId == ann.annId
        );
    }

    if (element) this.phraseList.onMouseLeave(element);
  }

  onAnnClicked(ann: AnnotationElement) {
    let element: IndElement;
    if (ann.annIdPrefix == 'img') {
      element = this.displayedImages.find((r) => r.elementId == ann.annId);
    } else {
      element = this.displayedPhrases.find((r) => r.elementId == ann.annId);
    }
    if (element) {
      this.ngRedux.dispatch({
        type: actionsList.CAT_PHRASE_ANN_CLICKED,
        data: element,
      });
    }
  }

  editSelected() {
    this.editPhraseTypeOpenSw = true;
  }

  onBulkEditSave(phraseType: TreeNode) {
    this.catService
      .updBulkIndDocPhrase(
        this.selectedPhrases,
        phraseType.parent.data.typeCode,
        phraseType.data.typeCode
      )
      .subscribe(
        (data) => {
          this.selectedPhrases.forEach((p) => {
            p.typeCode = phraseType.parent.data.typeCode;
            p.typeDescr = phraseType.parent.data.typeDescr;
            p.subTypeCode = phraseType.data.typeCode;
            p.subTypeDescr = phraseType.data.typeDescr;
          });
          this.selectedPhrases.forEach((p) => {
            p.checkedSw = false;
          });
          this.phraseBulkEditCmp.loading = false;
          this.editPhraseTypeOpenSw = false;
        },
        (err) => {
          this.phraseBulkEditCmp.loading = false;
        }
      );
  }

  onPanelResizeStart(event: any) {
    this.panelResizing = true;
  }

  onPanelResizeEnd(event: any) {
    this.panelResizing = false;
  }

  addExtRef() {
    //this.onAddExtRef.emit(this.selectedPhrases);
    this.addExtRefLoading = true;
    let cnt = 0;

    this.selectedPhrases.forEach((phrase) => {
      let extRef = new ExtRef();
      extRef.srcDocId =
        this.ngRedux.getState().curCatDocStatePrime.curCatDoc.docId;
      extRef.type = 'PHRASE';
      extRef.srcPhraseId = this.srcPhraseId;
      extRef.extDocId = this.doc.docId;
      extRef.extCatVersionId = this.extCatVersionId;
      extRef.extType = 'PHRASE';
      extRef.extPhraseId = phrase.phraseId;

      this.catService.updExtRefPointer(extRef).subscribe(
        (data) => {
          cnt++;
          if (cnt == this.selectedPhrases.length) this.onAddExtRef.emit();
        },
        (err) => {
          this.addExtRefLoading = false;
        }
      );
    });
  }

  postPhraseOrderEdit() {
    this.editPhraseOrderOpenSw = false;
    this.ngRedux.dispatch({
      type: actionsList.RELOAD_CAT_DOC_PAGE_REQ,
      data: true,
    });
  }

  downloadIndd() {
    this.downloadingZip = true;
    this.catService.getS3Objects([this.doc.inddS3Key]).subscribe(
      () => {        
        let url = this.catService.getS3TempObjectPresignedUrl(this.doc.inddS3Key);
        this.tryDownloadFile(url);
      },
      (err) => {
        this.downloadingZip = false;
      }
    );
  }

  tryDownloadFile(url: string) {
    this.sub?.unsubscribe();
    this.sub = this.catService.checkFileExists(url).subscribe(
      (exists) => {
        if (exists) {
          console.log(url);
          var element = document.createElement('a');
          element.href = url;
          element.setAttribute('download', this.doc.fileName);
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          this.downloadingZip = false;
        } else {
          setTimeout(() => {
            this.tryDownloadFile(url);
          }, 100);
        }
      },
      (err) => {
        this.downloadingZip = false;
      }
    );
  }

  onUpdateSpellingWhitelist() {
    this.docSpellcheckWhitelistingOpenSw = false;
    this.ngOnInit();
  }
}
