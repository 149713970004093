import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GrammarSpellingIssue, PhraseWord, ReferenceIssue } from '../../models/common';

interface PhraseWordDiff extends PhraseWord {
  formatDescrDiffOnly?: string;
}

export class PhraseWordWrapper {
  word: PhraseWordDiff;
  offset: number;
  length: number;
  grammarSpellingIssue: GrammarSpellingIssue;
  referenceIssues: ReferenceIssue[];
}

@Component({
  selector: 'formatted-phrase',
  templateUrl: './formatted-phrase.component.html',
  styleUrls: ['./formatted-phrase.component.scss']
})

export class FormattedPhraseComponent implements OnInit {

  @Input() phraseWords: PhraseWordDiff[];
  @Input() grammarSpellingIssues: GrammarSpellingIssue[];
  @Input() referenceIssues: ReferenceIssue[];
  @Input() formatType?: 'formatMatchEntry' | 'formatMatchPhrase';
  @Input() formatMatchDifferences?: any[] = [];
  @Input() highlightedIds?: number[] = [];
  @Output() highlightedIdsChange = new EventEmitter<number[]>();
  @Output() onAddToWhitelist = new EventEmitter();

  refCharPositions: boolean[];

  constructor() { 
    this.refCharPositions = [];
  }

  ngOnInit(): void {
    this.phraseWords.forEach(word => {
      const matchingDiff = this.formatMatchDifferences.find(diff => diff.ids.includes(word.id));

      if (matchingDiff) {
        // Tokenize the properties from matched difference except "text" and "ids"
        const { text, ids, ...tokenizedProps } = matchingDiff;

        // Update the formatDescr property in word
        const updatedFormatDescr = Object.keys(tokenizedProps)
          .map(prop => `${prop}:${tokenizedProps[prop][this.formatType === 'formatMatchEntry' ? 0 : 1]}`)
          .join(', ');

        // Replace boolean values underscore properties with friendly names
        const regexPattern = /(?:bold|italic|superscript|font_name|font_size|color_hex):([^\s,]+)/g;
        word.formatDescrDiffOnly = updatedFormatDescr.replace(regexPattern, (match, group) => {
          if (group === 'True') {
            return match.replace(':True', '');
          } else if (group === 'False') {
            return 'non-' + match.replace(':False', '');
          } else {
            const prop = match.split(':')[0];
            let propFriendly;
            switch (prop) {
              case 'font_size':
                propFriendly = 'font size';
                break;
              case 'font_name':
                propFriendly =  'font name';
                break;
              case 'color_hex':
                propFriendly =  'color';
                break;
              default:
                return match;
            }
            // strips curly braces on the end
            return propFriendly + match.replace(prop, '').replace(/\{|\}/g, '');
          }
        });
      }
    });
  }

  getWrappedWord(word: PhraseWord): PhraseWordWrapper {
    let wordWrapper = new PhraseWordWrapper();
    wordWrapper.word = word;
    let { offset, length} = this.getWordOffsetAndLengthInPhrase(word);
    wordWrapper.offset = offset;
    wordWrapper.length = length;
    wordWrapper.grammarSpellingIssue = this.getWordIssue(wordWrapper);
    wordWrapper.referenceIssues = this.getReferenceIssues(word);
    return wordWrapper;
  }

  updateHighlightedId(id?: number): void {
    this.highlightedIdsChange.emit(id ? [id] : []);
  }

  isHighlighted(id: number): boolean {
    return this.highlightedIds.includes(id);
  }

  getWordIssue(wrappedWord: PhraseWordWrapper): GrammarSpellingIssue {
    let { offset, length } = wrappedWord;
    return this.grammarSpellingIssues?.find(issue => offset >= issue.offset && (offset + length) <= (issue.offset + issue.length));
  }

  getReferenceIssues(word: PhraseWordDiff): ReferenceIssue[] {
    return this.referenceIssues?.filter(issue => issue.refPointerWordId == word.id && issue.refFinding == 'UNRESOLVED_REFERENCE' && issue.isPrimary) || [];
  }

  getWordOffsetAndLengthInPhrase(word: PhraseWord): any {
    let idx: number = 0;
    let first: boolean = true;

    for (let phraseWord of this.phraseWords) {
      if (!first && !phraseWord.concatSw) {
        idx += 1;
      }

      if (phraseWord === word) {
        return {offset: idx, length: phraseWord.text.length};
      }

      idx += phraseWord.text.length;

      if (first) {
        first = false;
      }
    }

    throw Error("Word not found");
  }

  makePhrase(): string {
    let first = true;
    let res = [];

    for (let phraseWord of this.phraseWords) {
      if (!first && !phraseWord.concatSw) {
        res.push(' ');
      } 
      res.push(phraseWord.text);
      first = false;
    }

    return res.join("");
  }

  isReferenceIssueOnIndex(referenceIssues: ReferenceIssue[], chars: any[], charIndex: number): boolean {
    let refCharPositions = new Array(chars.length).fill(false);
    if (!referenceIssues || referenceIssues.length === 0){
      return false;
    }

    for (let k = 0; k < referenceIssues.length; k++) {
      let refChar = referenceIssues[k].refChar

      const refCharLength = refChar.length;
      for (let i = 0; i <= chars.length - refCharLength; i++) {
        const substring = chars.slice(i, i + refCharLength).map(c => c.text).join('');
        if (substring === refChar) {
          for (let j = 0; j < refCharLength; j++) {
            refCharPositions[i + j] = true;
          }
        }
      }
  }

    return refCharPositions[charIndex];
  }

  addToWhiteList(grammarSpellingIssue: GrammarSpellingIssue) {
    let phrase = this.makePhrase();
    let whitelistedWord = phrase.slice(grammarSpellingIssue.offset, grammarSpellingIssue.offset + grammarSpellingIssue.length);
    this.onAddToWhitelist.emit(whitelistedWord);
  }
}