<modal-popup
  [flexible]="true"
  [focusOnOpen]="false"
  [isOpen]="true"
  (onClose)="close()"
  [title]="
    parentDoc ? 'translate.cat.addCatDocVersion' : 'translate.cat.addCatDoc'
  "
>
  <div class="container">
    <!--doc name-->
    <input-wrap
      label="{{ 'translate.general.docName' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <input
        type="text"
        [(ngModel)]="docName"
        class="t-input"
        [disabled]="parentDoc"
      />
    </input-wrap>

    <!--asset type-->
    <input-wrap
      label="{{ 'translate.general.assetType' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <p-dropdown
        [options]="assetTypes"
        optionLabel="text"
        [(ngModel)]="assetType"
        class="l-cell-spread-x"
        [placeholder]="'translate.general.select' | translate"
        appendTo="body"
        baseZIndex="100"
      >
      </p-dropdown>
    </input-wrap>

    <!--file upload-->
    <div class="upload-container">
      <input-wrap
        *ngIf="!isUploading && !docFileName"
        [customValidation]="isValidFile"
        [customValidationWarn]="
          'translate.validation.revDocFileTypes' | translate
        "
        [cssClass]="'l-ta-center input-wrap-hasFileSpread'"
      >
        <input
          id="uploadFileInput"
          (change)="uploadFile($event)"
          class="t-fileInput-spread"
          type="file"
          accept=".pdf,.zip"
        />

        <button class="t-button">
          {{ "translate.general.selectFile" | translate }}
        </button>
      </input-wrap>
      <spinner *ngIf="isUploading" class="l-ta-center"></spinner>
      <p *ngIf="docFileName">
        <span>{{ fileName }}</span>
        <a (click)="removeFile()">
          ({{ "translate.general.remove" | translate }})</a
        >
      </p>
    </div>

    <div class="actions">
      <button class="t-button-outline" (click)="close()">
        {{ "translate.general.cancel" | translate }}
      </button>
      &nbsp;
      <button
        class="t-button"
        [disabled]="!enableSubmit || saving"
        (click)="createDoc()"
      >
        {{ "translate.general.ok" | translate }}
      </button>
    </div>
  </div>

  <loader *ngIf="saving"></loader>
</modal-popup>
