import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProcessRunStatusEnum } from '@shared/models/common';
import { NgRedux } from '@shared/redux/redux-compatibility.service';
import { actionsList, AppState } from '@shared/redux/store';
import { AuthService } from '@shared/services/auth.service';
import { CommonService } from '@shared/services/common.service';
import { GrammarSpellingCheckService } from '@shared/services/grammar-spelling-check.service';
import { merge, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'doc-spellcheck-whitelisting',
  templateUrl: './doc-spellcheck-whitelisting.component.html',
  styleUrl: './doc-spellcheck-whitelisting.component.scss'
})
export class DocSpellcheckWhitelistingComponent implements OnInit {

  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Input() docId;
  @Input() docMode: "ind" | "rev";
  sub: Subscription;
  searchStatusIntervalSec: number = 2;
  processStatusCheckIntervalMillis = 1000;
  loading: boolean;
  spellingErrors: string[];
  spellingWhitelist: string[] = [];

  constructor(
    private authService: AuthService,
    private ngRedux: NgRedux<AppState>,
    private commonService: CommonService,
    private grammarSpellingService: GrammarSpellingCheckService
  ) {}

  ngOnInit(): void {
    this.loadSpellingErrors(this.docMode);
  }

  loadSpellingErrors(docMode: "ind" | "rev") {
    this.loading = true;

    this.grammarSpellingService
      .getDocumentGrammarSpellingIssues(this.docId, docMode)
      .subscribe(
        (issues) => {
          const spellingIssues = new Set<string>();
          issues.filter(issue => issue.issueType == "SPELLING").forEach(issue => {
            spellingIssues.add(issue.getIssueText());
          });
          
          this.spellingErrors = Array.from(spellingIssues);
          this.spellingErrors.sort();
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.ngRedux.dispatch({
            type: actionsList.UPD_DOC_PAGE_LOADING,
            data: false,
          });
        }
      )
  }

  close() {
    this.onClose.emit();
  }

  save() {
    if (this.spellingWhitelist.length > 0) {
      this.loading = true;
      this.processWhitelistedWords().subscribe(
        _ => {
          const runType = this.docMode === "ind" ? "IND_SPELLING_LIGHT" : "REV_SPELLING_LIGHT";
          this.commonService.addAlgRunHead({doc_id: this.docId, run_type: runType}).subscribe(
            runNum => {
              this.grammarSpellingService.updateSpellingLight(runNum, this.spellingWhitelist).subscribe(
                _ => {
                  let timer = setInterval(() => {
                    this.authService.getProcessStatus(runNum).subscribe(
                      (data) => {
                        if (data.status == ProcessRunStatusEnum.FINISHED || data.status == ProcessRunStatusEnum.FAILED) {
                          clearInterval(timer);
                          this.loading = false;
                          this.onSave.emit();
                        }
                      },
                      (err) => {
                        this.loading = false;
                        clearInterval(timer);
                      }
                    );
                  }, this.processStatusCheckIntervalMillis);
                }
              )
            },
            err => {
              this.loading = false;
            }
          );
        },
        err => {
          this.loading = false;
        }
      );
    } else {
      this.onClose.emit();
    }
  }

  processWhitelistedWords(): Observable<any> {
      const catId = this.docMode === "ind" ? this.ngRedux.getState().curCat.catId : this.ngRedux.getState().curRevDoc.catId;;
      return this.grammarSpellingService.addCatSpellingCheckIgnoredWords(catId, this.spellingWhitelist);
    }

}
