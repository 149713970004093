import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

// redux
import { NgReduxInitService } from '@shared/redux/redux-initialize.service';

// http client authentication interceptor
import { AuthInterceptor } from '@shared/interceptors/auth.interceptor';

// modules: ngs-translate
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
  //TranslateDirective,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { SharedService } from './shared/services/shared.service';
import { AwsService } from './shared/services/aws.service';
import { AuthService } from './shared/services/auth.service';
import { UiService } from './shared/services/ui.service';
import { ValidationPipe } from './shared/components/ui/validation.pipe';
import { PhoneAreaCodePipe } from './shared/components/ui/pipes/phone-area-code.pipe';
import { FormatPhonePipe } from './shared/components/ui/pipes/format-phone.pipe';
import { ReferenceIssueCheckPipe } from './shared/components/ui/pipes/reference-issue.pipe';
import { CanDeactivateGuardService } from './shared/services/can-deactivate-guard.service';
import { UploadService } from './shared/services/upload.service';
import { DatePipe } from '@angular/common';
import { DirectAccessGuard } from './direct-access-guard';
import { CustomRouteReuseStrategy, ReuseStrategy } from './app-routing.config';
import { LoaderComponent } from './shared/components/ui/loader/loader.component';
import { PopupComponent } from './shared/components/ui/popup.component';
import { SpinnerComponent } from './shared/components/ui/spinner/spinner.component';
import { ModalPopupComponent } from './shared/components/ui/modal-popup.component';
import { TranslateDirective } from './shared/components/ui/translate.directive';
import { CheckboxWrapComponent } from './shared/components/ui/checkbox-wrap.component';
import { TruncatePipe } from './shared/components/utils/truncate';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

function reduxProviderFactory(provider: NgReduxInitService): () => Promise<boolean> {
  return () => provider.initialize();
}

//ui
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { TreeModule } from 'primeng/tree';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { TreeTableModule } from 'primeng/treetable';
import { PanelMenuModule } from 'primeng/panelmenu';
import { InplaceModule } from 'primeng/inplace';
import { CheckboxModule } from 'primeng/checkbox';
import { OrderListModule } from 'primeng/orderlist';
import { PanelModule } from 'primeng/panel';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SplitterModule } from 'primeng/splitter';
import { FileUploadModule } from 'primeng/fileupload';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ProgressBarModule } from 'primeng/progressbar';
import { TabMenuModule } from 'primeng/tabmenu';
import { DividerModule } from 'primeng/divider';

import {
  faEye,
  faTimes,
  faUserCircle,
  faExclamationCircle,
  faAngleRight,
  faArrowLeft,
  faFileCircleCheck,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';

import { SelectEnvComponent } from './shared/components/select-env/select-env.component';

import { InputTextModule } from 'primeng/inputtext';
import { InputWrapComponent } from './shared/components/ui/input-wrap.component';
import { LayoutComponent } from './layout/layout.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { RevDocListComponent } from './rev-doc-list/rev-doc-list.component';
import { RevService } from './shared/services/rev.service';
import { TableComponent } from './shared/components/ui/table/table.component';
import { TooltipDirective } from './shared/components/ui/tooltip.directive';
import { SlideInOutComponent } from './shared/components/ui/slide-in-out.component';
import { RevDocComponent } from './rev-doc/rev-doc.component';
import { DataBoxComponent } from './shared/components/ui/data-box.component';
import { RevDocRulesListComponent } from './rev-doc/rev-doc-rules-list/rev-doc-rules-list.component';
import { RevDocRuleTileComponent } from './rev-doc/rev-doc-rule-tile/rev-doc-rule-tile.component';
import { DocViewerComponent } from './shared/components/doc-viewer/doc-viewer.component';
import { RevRuleDetailsComponent } from './rev-doc/rev-rule-details/rev-rule-details.component';
import { PhraseRevRuleDetailsComponent } from './rev-doc/phrase-rev-rule-details/phrase-rev-rule-details.component';
import { DocToolbarComponent } from './shared/components/doc-viewer/doc-toolbar/doc-toolbar.component';
import { AddRevDocComponent } from './rev-doc-list/add-rev-doc/add-rev-doc.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { CatalogListComponent } from './catalog-list/catalog-list.component';
import { TabsComponent } from './shared/components/ui/tabs/tabs.component';
import { TabSingleComponent } from './shared/components/ui/tabs/tab-single/tab-single.component';
import { TabButtonComponent } from './shared/components/ui/tabs/tab-button/tab-button.component';
import { CatService } from './shared/services/cat.service';
import { CreateCatComponent } from './catalog-list/create-cat/create-cat.component';
import { CatalogComponent } from './catalog/catalog.component';
import { CatEntriesComponent } from './catalog/cat-entries/cat-entries.component';
import { CatDocsComponent } from './catalog/cat-docs/cat-docs.component';
import { CatDocComponent } from './cat-doc/cat-doc.component';
import { CatDocPhraseListComponent } from './cat-doc/cat-doc-phrase-list/cat-doc-phrase-list.component';
import { CatDocPhraseTileComponent } from './cat-doc/cat-doc-phrase-tile/cat-doc-phrase-tile.component';
import { CatDocPhraseDetailsComponent } from './cat-doc/cat-doc-phrase-details/cat-doc-phrase-details.component';
import { CatPhraseRefComponent } from './cat-doc/cat-phrase-ref/cat-phrase-ref.component';
import { CatMatchPhraseComponent } from './cat-doc/cat-match-phrase/cat-match-phrase.component';
import { CatMatchPhraseRefComponent } from './cat-doc/cat-match-phrase-ref/cat-match-phrase-ref.component';
import { CatAddInstrComponent } from './cat-doc/cat-add-instr/cat-add-instr.component';
import { CatMatchComponent } from './cat-doc/cat-match/cat-match.component';
import { CreateCatDocComponent } from './catalog/cat-docs/create-cat-doc/create-cat-doc.component';
import { ImportClaimsComponent } from './catalog/cat-docs/import-claims/import-claims.component';
import { CatEntryDocsComponent } from './cat-doc/cat-entry-docs/cat-entry-docs.component';
import { MngCatPhraseTypesComponent } from './catalog/mng-cat-phrase-types/mng-cat-phrase-types.component';
import { EditPhraseSubTypeComponent } from './catalog/mng-cat-phrase-types/edit-phrase-sub-type/edit-phrase-sub-type.component';
import { EditPhraseTypeComponent } from './catalog/mng-cat-phrase-types/edit-phrase-type/edit-phrase-type.component';
import { ImportTypeFromCatComponent } from './catalog/mng-cat-phrase-types/import-type-from-cat/import-type-from-cat.component';
import { CatPhraseTypeListComponent } from './catalog/mng-cat-phrase-types/cat-phrase-type-list/cat-phrase-type-list.component';
import { MngEnvComponent } from './manage/mng-env/mng-env.component';
import { MngUsersComponent } from './manage/mng-users/mng-users.component';
import { EditUserComponent } from './manage/mng-users/edit-user/edit-user.component';
import { EditEnvComponent } from './manage/mng-env/edit-env/edit-env.component';
import { MngProductsComponent } from './manage/mng-products/mng-products.component';
import { EditProductComponent } from './manage/mng-products/edit-product/edit-product.component';
import { CardsComponent } from './catalog/cards/cards.component';
import { CardComponent } from './catalog/cards/card/card.component';
import { CardEntriesComponent } from './catalog/cards/card/card-entries/card-entries.component';
import { CatBoundingBoxComponent } from './cat-doc/cat-bounding-box/cat-bounding-box.component';
import { CatDocRefOptionsComponent } from './cat-doc/cat-doc-ref-options/cat-doc-ref-options.component';
import { FormattedPhraseComponent } from './shared/components/formatted-phrase/formatted-phrase.component';
import { ProcessingLabelComponent } from './shared/components/processing-label/processing-label.component';
import { MngCatAssetTypesComponent } from './catalog/mng-cat-asset-types/mng-cat-asset-types.component';
import { AddAssetTypeComponent } from './catalog/mng-cat-asset-types/add-asset-type/add-asset-type.component';
import { MngAssetTypesComponent } from './manage/mng-asset-types/mng-asset-types.component';
import { EditAssetTypeComponent } from './manage/mng-asset-types/edit-asset-type/edit-asset-type.component';
import { PdfJsViewerComponent, PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { PdfViewerComponent } from './shared/components/pdf-viewer/pdf-viewer.component';
import { CatPhraseBulkEditComponent } from './cat-doc/cat-phrase-bulk-edit/cat-phrase-bulk-edit.component';
import { CatImagesComponent } from './catalog/cat-images/cat-images.component';
import { CreateBulkCatDocComponent } from './catalog/cat-docs/create-bulk-cat-doc/create-bulk-cat-doc.component';
import { CatDocCardsComponent } from './cat-doc/cat-doc-cards/cat-doc-cards.component';
import { CardSummaryComponent } from './catalog/cards/card/card-summary/card-summary.component';
import { ProcessRunStatusComponent } from './shared/components/process-run-status/process-run-status.component';
import { AsyncImgComponent } from './shared/components/ui/async-img/async-img.component';
import { MngTaggedDocsComponent } from './manage/mng-tagged-docs/mng-tagged-docs.component';
import { LabelChangeComponent } from './lc/label-change/label-change.component';
import { CreateLcComponent } from './lc/create-lc/create-lc.component';
import { LcDetailsComponent } from './lc/lc-details/lc-details.component';
import { SelectLCCatComponent } from './lc/add-docs/select-cat/select-cat.component';
import { SelectLCDocsComponent } from './lc/add-docs/select-docs/select-docs.component';
import { CatTagDocsComponent } from './manage/mng-tagged-docs/cat-tag-docs/cat-tag-docs.component';
import { LCDocListComponent } from './lc/lc-doc-list/lc-doc-list.component';
import { LcDocsComponent } from './lc/lc-details/lc-docs/lc-docs.component';
import { LcCardsComponent } from './lc/lc-details/lc-cards/lc-cards.component';
import { LcPhrasesComponent } from './lc/lc-details/lc-phrases/lc-phrases.component';
import { LcDocSummaryComponent } from './lc/lc-doc-summary/lc-doc-summary.component';
import { BbCardComponent } from './lc/lc-details/lc-cards/bb-card/bb-card.component';
import { LcDocCardsComponent } from './lc/lc-doc-summary/lc-doc-cards/lc-doc-cards.component';
import { SelectPhrasesComponent } from './lc/lc-details/lc-phrases/select-phrases/select-phrases.component';
import { LcDocPhrasesComponent } from './lc/lc-doc-summary/lc-doc-phrases/lc-doc-phrases.component';
import { PullDocProcessComponent } from './shared/components/pull-doc-process/pull-doc-process.component';
import { BbTileNewComponent } from './catalog/cards/card/bb-tile-new/bb-tile-new.component';
import { SelectLcCardsComponent } from './lc/lc-details/lc-cards/select-lc-cards/select-lc-cards.component';
import { CardMatchComponent } from './rev-doc/card-match/card-match.component';
import { CardDisplayComponent } from './rev-doc/card-display/card-display.component';
import { RevDocCardsComponent } from './rev-doc/rev-doc-cards/rev-doc-cards.component';
import { CardAssetTemplatesComponent } from './catalog/card-asset-templates/card-asset-templates.component';
import { CreateCardAssetTemplateComponent } from './catalog/card-asset-templates/create-card-asset-template/create-card-asset-template.component';
import { CardAssetTemplateDetailsComponent } from './catalog/card-asset-templates/card-asset-template-details/card-asset-template-details.component';
import { AddCardsToTemplateComponent } from './catalog/card-asset-templates/add-cards-to-template/add-cards-to-template.component';
import { CardTransComponent } from './catalog/cards/card/card-trans/card-trans.component';
import { CatExtRefsComponent } from './catalog/cat-ext-refs/cat-ext-refs.component';
import { CatExtLibsComponent } from './catalog/cat-ext-libs/cat-ext-libs.component';
import { AddExtRefComponent } from './cat-doc/add-ext-ref/add-ext-ref.component';
import { EditPhraseOrderComponent } from './cat-doc/edit-phrase-order/edit-phrase-order.component';
import { CatTblMatchComponent } from './cat-doc/cat-tbl-match/cat-tbl-match.component';
import { BreadcrumbsComponent } from './layout/breadcrumbs/breadcrumbs.component';
import { DirectlinkComponent } from './directlink/directlink.component';
import { DlRevAnnComponent } from './directlink/dl-rev-ann/dl-rev-ann.component';
import { EditCardOrderComponent } from './catalog/card-asset-templates/edit-card-order/edit-card-order.component';
import { CatAltRefComponent } from './catalog/cat-alt-ref/cat-alt-ref.component';
import { AddToAltRefComponent } from './catalog/add-to-alt-ref/add-to-alt-ref.component';
import { EditAltRefComponent } from './catalog/cat-alt-ref/edit-alt-ref/edit-alt-ref.component';
import { ConfigureIntegrationComponent } from './catalog/cat-docs/configure-integration/configure-integration.component';
import { DynamicFormComponent } from './shared/dynamic-form/dynamic-form.component';
import { GrammarSpellingFormattedWordComponent } from './shared/components/grammar-spelling-formatted-word/grammar-spelling-formatted-word.component';
import { SpellingCheckIgnoredComponent } from './catalog/spelling-check-ignored/spelling-check-ignored.component';
import { AddSpellingCheckIgnoredComponent } from './catalog/spelling-check-ignored/add-spelling-check-ignored/add-spelling-check-ignored.component';
import { DocSpellcheckWhitelistingComponent } from './shared/components/doc-spellcheck-whitelisting/doc-spellcheck-whitelisting.component';
import { CatDocAssocTextOptionsComponent } from './cat-doc/cat-doc-assoc-text-options/cat-doc-assoc-text-options.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoaderComponent,
    PopupComponent,
    SpinnerComponent,
    ModalPopupComponent,
    TranslateDirective,
    TruncatePipe,
    ReferenceIssueCheckPipe,
    CheckboxWrapComponent,
    SelectEnvComponent,
    InputWrapComponent,
    LayoutComponent,
    NavbarComponent,
    RevDocListComponent,
    TableComponent,
    TooltipDirective,
    SlideInOutComponent,
    RevDocComponent,
    DataBoxComponent,
    RevDocRulesListComponent,
    RevDocRuleTileComponent,
    DocViewerComponent,
    RevRuleDetailsComponent,
    PhraseRevRuleDetailsComponent,
    DocToolbarComponent,
    AddRevDocComponent,
    SidebarComponent,
    CatalogListComponent,
    TabsComponent,
    TabSingleComponent,
    TabButtonComponent,
    CreateCatComponent,
    CatalogComponent,
    CatEntriesComponent,
    CatDocsComponent,
    CatDocComponent,
    CatDocPhraseListComponent,
    CatDocPhraseTileComponent,
    CatDocPhraseDetailsComponent,
    CatPhraseRefComponent,
    CatMatchPhraseComponent,
    CatMatchPhraseRefComponent,
    CatAddInstrComponent,
    CatMatchComponent,
    SlideInOutComponent,
    CreateCatDocComponent,
    ImportClaimsComponent,
    CatEntryDocsComponent,
    MngCatPhraseTypesComponent,
    EditPhraseSubTypeComponent,
    EditPhraseTypeComponent,
    ImportTypeFromCatComponent,
    CatPhraseTypeListComponent,
    MngEnvComponent,
    MngUsersComponent,
    EditUserComponent,
    EditEnvComponent,
    MngProductsComponent,
    EditProductComponent,
    CardsComponent,
    CardComponent,
    CardEntriesComponent,
    CatBoundingBoxComponent,
    CatDocRefOptionsComponent,
    FormattedPhraseComponent,
    ProcessingLabelComponent,
    MngCatAssetTypesComponent,
    AddAssetTypeComponent,
    MngAssetTypesComponent,
    EditAssetTypeComponent,
    PdfViewerComponent,
    CatPhraseBulkEditComponent,
    CatImagesComponent,
    CreateBulkCatDocComponent,
    CatDocCardsComponent,
    CardSummaryComponent,
    ProcessRunStatusComponent,
    AsyncImgComponent,
    MngTaggedDocsComponent,
    LabelChangeComponent,
    CreateLcComponent,
    LcDetailsComponent,
    SelectLCCatComponent,
    SelectLCDocsComponent,
    CatTagDocsComponent,
    LCDocListComponent,
    LcDocsComponent,
    LcCardsComponent,
    LcPhrasesComponent,
    LcDocSummaryComponent,
    BbCardComponent,
    LcDocCardsComponent,
    SelectPhrasesComponent,
    LcDocPhrasesComponent,
    PullDocProcessComponent,
    BbTileNewComponent,
    SelectLcCardsComponent,
    CardMatchComponent,
    CardDisplayComponent,
    RevDocCardsComponent,
    CardAssetTemplatesComponent,
    CreateCardAssetTemplateComponent,
    CardAssetTemplateDetailsComponent,
    AddCardsToTemplateComponent,
    CardTransComponent,
    CatExtRefsComponent,
    CatExtLibsComponent,
    AddExtRefComponent,
    EditPhraseOrderComponent,
    CatTblMatchComponent,
    BreadcrumbsComponent,
    DirectlinkComponent,
    DlRevAnnComponent,
    EditCardOrderComponent,
    CatAltRefComponent,
    AddToAltRefComponent,
    EditAltRefComponent,
    ConfigureIntegrationComponent,
    DynamicFormComponent,
    GrammarSpellingFormattedWordComponent,
    SpellingCheckIgnoredComponent,
    AddSpellingCheckIgnoredComponent,
    DocSpellcheckWhitelistingComponent,
    CatDocAssocTextOptionsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FontAwesomeModule,
    InputTextModule,
    TableModule,
    DropdownModule,
    SelectButtonModule,
    SidebarModule,
    TreeModule,
    OverlayPanelModule,
    InputTextareaModule,
    ListboxModule,
    TreeTableModule,
    PanelMenuModule,
    InplaceModule,
    CheckboxModule,
    OrderListModule,
    PanelModule,
    MultiSelectModule,
    RadioButtonModule,
    PdfJsViewerModule,
    SplitterModule,
    FileUploadModule,
    ProgressBarModule,
    TabMenuModule,
    DividerModule,
    NgCircleProgressModule.forRoot({
      backgroundPadding: 0,
      radius: 25,
      space: 3,
      unitsFontSize: '11px',
      outerStrokeWidth: 5,
      outerStrokeColor: '#62bd60',
      outerStrokeLinecap: 'butt',
      innerStrokeColor: '#0978a2',
      innerStrokeWidth: 2,
      titleFontSize: '12px',
      imageHeight: 88,
      showSubtitle: false,
      showBackground: false,
      startFromZero: false,
      animation: true,
    }),
  ],
  providers: [
    DirectAccessGuard,
    SharedService,
    AwsService,
    AuthService,
    UiService,
    UploadService,
    ValidationPipe,
    CanDeactivateGuardService,
    UploadService,
    DatePipe,
    CatService,
    RevService,
    {
      provide: RouteReuseStrategy,

      useClass: ReuseStrategy, //CustomRouteReuseStrategy
    },
    NgReduxInitService,
    { provide: APP_INITIALIZER, useFactory: reduxProviderFactory, deps: [NgReduxInitService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faEye,
      faTimes,
      faUserCircle,
      faExclamationCircle,
      faAngleRight,
      faArrowLeft,
      faFileCircleCheck,
      faDownload
    );
  }
}
