import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CatDoc, IndPhrase } from '@shared/models/cat.model';
import { NgRedux } from '@shared/redux/redux-compatibility.service';
import { AppState } from '@shared/redux/store';
import { CatService } from '@shared/services/cat.service';

@Component({
  selector: 'cat-doc-assoc-text-options',
  templateUrl: './cat-doc-assoc-text-options.component.html',
  styleUrl: './cat-doc-assoc-text-options.component.scss'
})
export class CatDocAssocTextOptionsComponent implements OnInit {
  @Output() onAssocTextAdd = new EventEmitter<IndPhrase>();
  @Output() onAssocTextRemove = new EventEmitter<IndPhrase>();

  loading: boolean;

  get curPhrase(): IndPhrase {
    return this.ngRedux.getState().curCatElement as IndPhrase;
  }

  get curDoc(): CatDoc {
    return this.ngRedux.getState().curCatDocStatePrime.curCatDoc;
  }

  get assocTextOptions(): IndPhrase[] {
    return this.ngRedux.getState().curCatDocStatePrime.catDocRefOptions;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService
  ) {}

  ngOnInit(): void {
    if (
      !this.ngRedux.getState().curCatDocStatePrime.catDocRefOptions ||
      this.ngRedux.getState().curCatDocStatePrime.catDocRefOptions.length == 0
    ) {
      this.loading = true;
      this.catService.getIndDocRefOptions(this.curDoc).subscribe(
        (data) => {
          this.loading = false;
        },
        () => (this.loading = false)
      );
    }
  }

  add(phrase: IndPhrase) {
    this.onAssocTextAdd.emit(phrase);
  }

  remove(phrase: IndPhrase) {
    this.onAssocTextRemove.emit(phrase);
  }

  isSelected(phrase: IndPhrase): boolean {
    return (
      this.curPhrase.associatedText.filter((r) => r.assocPhraseId == phrase.phraseId)
        .length > 0
    );
  }

}
