import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DataElement, FileUpload, Product } from 'src/app/shared/models/common';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState } from 'src/app/shared/redux/store';
import { RevService } from 'src/app/shared/services/rev.service';
import { RevCatalog, RevDoc } from 'src/app/shared/models/rev.model';
import { UploadService } from 'src/app/shared/services/upload.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';

@Component({
  selector: 'add-rev-doc',
  templateUrl: './add-rev-doc.component.html',
  styleUrls: ['./add-rev-doc.component.scss'],
})
export class AddRevDocComponent implements OnInit {
  @Input() parentDoc: RevDoc;
  @Output() onClose = new EventEmitter();
  @Output() onDocCreated = new EventEmitter<RevDoc>();

  docName: string;
  product: Product;
  catalog: RevCatalog;
  catalogList: RevCatalog[] = [];
  isUploading: boolean;
  isValidFile: boolean;
  docS3Key: string;
  docFileName: string;
  fileName: string;
  saving: boolean;
  assetType: DataElement;

  get productList(): Product[] {
    return this.ngRedux.getState().productList;
  }

  get assetTypes(): DataElement[] {
    if (!this.catalog) return [];
    return this.catalog.catAssetTypeList
      .sort((a, b) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
      });
  }

  get enableSubmit(): boolean {
    return (
      this.docName != null &&
      this.product != null &&
      this.catalog != null &&
      this.assetType != null &&
      this.docFileName != null
    );
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private revService: RevService,
    private uploadService: UploadService,
    private authService: AuthService,
    private sharedService: SharedService,
    private catService: CatService
  ) {}

  ngOnInit(): void {
    if (this.parentDoc) {
      this.docName = this.parentDoc.docName;
      this.product = this.productList.find(
        (p) => p.id == this.parentDoc.productId
      );
      this.getCatalogList();
    }
  }

  close() {
    this.onClose.emit();
  }

  onProductChange() {
    this.getCatalogList();
  }

  getCatalogList() {
    this.catalogList = [];
    this.catalogList.loading = true;

    this.revService.getRevCatalogList(this.product).subscribe(
      (data) => {
        this.catalogList = data;
      },
      (err) => (this.catalogList.loading = false)
    );
  }

  uploadFile($event) {
    const fileData: File = $event.target.files[0];
    if (!fileData) return;
    this.isValidFile = undefined;
    this.docFileName = null;
    this.fileName = null;

    this.isValidFile = this.uploadService.validateFile(
      fileData,
      'pdf,zip'
      //SharedService.revDocFileTypes
    );
    if (!this.isValidFile) return;

    this.isUploading = true;

    const file: FileUpload = new FileUpload();
    file.fileType = this.uploadService.getFileType(fileData);
    this.fileName = fileData.name;

    (async () => {
      await this.uploadService.uploadFile(fileData);
      file.file = fileData;

      this.docFileName = null;

      this.uploadService
        .getPresignedUrl(file, this.authService.getPresignedUrl)
        .subscribe(
          (data) => {
            this.uploadService.putFile(file, data).subscribe(
              (res) => {
                this.docFileName = file.file.name;
                this.docS3Key = data.s3Key;
                this.isUploading = false;
              },
              (err) => {
                this.sharedService.alertError(err);
                this.isUploading = false;
              }
            );
          },
          (err) => {
            this.sharedService.alertError(err);
            this.isUploading = false;
          }
        );
    })();

    $event.target.value = '';
  }

  removeFile() {
    this.docFileName = null;
    this.fileName = null;
  }

  createDoc() {
    this.saving = true;

    let doc = new RevDoc();
    doc.docName = this.docName;
    if (this.parentDoc) {
      doc.parentDocId = this.parentDoc.parentDocId;
    }
    doc.docName = this.docName;
    doc.productId = this.product.id;
    doc.catId = this.catalog.catId;
    doc.catVersionId = this.catalog.catVersionId;
    doc.docS3Key = this.docS3Key;
    doc.fileName = this.docFileName;
    doc.assetType = this.assetType;

    var encryptSw = !doc.fileName.toLocaleLowerCase().endsWith('.zip');

    this.revService.createRevDoc(doc).subscribe(
      (savedDoc) => {
        if (encryptSw) {
          this.uploadService
            .encryptFile(
              savedDoc.docId,
              'REV',
              this.ngRedux.getState().curEnv.tmpS3Bucket,
              this.ngRedux.getState().curEnv.docS3Bucket,
              doc.docS3Key
            )
            .subscribe(
              (res) => {
                this.catService
                  .processIndDocAlg(savedDoc.runNum)
                  .subscribe((data) => {});
                this.saving = false;
                this.onDocCreated.emit(savedDoc);
              },
              (err) => {
                this.sharedService.alertError(err);
                this.saving = false;
              }
            );
        } else {
          this.catService
            .processIndDocAlg(savedDoc.runNum)
            .subscribe((data) => {});
          this.saving = false;
          this.onDocCreated.emit(savedDoc);
        }
      },

      (err) => (this.saving = false)
    );
  }
}
