import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '@shared/services/auth.service';
import { Config } from 'src/app/config';
import { NgRedux } from '@shared/redux/redux-compatibility.service';
import { AppState } from '@shared/redux/store';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  static excludedUrls: string[] = [];
  static urlPatternsIgnoredLastActivityReset: string[] = [];

  constructor(private authService: AuthService, private ngRedux: NgRedux<AppState>) {
    AuthInterceptor.excludedUrls = Config.assetConfigData['urlPatternsExcludedAuthentication'];
    AuthInterceptor.urlPatternsIgnoredLastActivityReset = Config.assetConfigData['urlPatternsIgnoredLastActivityReset'];
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // check if the intercepted request was on the exclusion list
    if(AuthInterceptor.excludedUrls.some(url => request.url.indexOf(url) !== -1)) { 
      console.log('Skipping url', request.url)
      return next.handle(request);
    }

    return from(this.authService.getToken())
          .pipe(
            switchMap(token => {
              if (token) {
                if(!AuthInterceptor.urlPatternsIgnoredLastActivityReset.some(url => request.url.indexOf(url) !== -1)) { 
                  this.authService.simulateUserActivity(); // reset the last activity time
                }
                let authorizedRequest = request.clone({
                  headers: request.headers.set('Authorization', 'Bearer ' + token)
                });

                if (request.url.indexOf(Config.assetConfigData['scaiServiceUrl']) !== -1) {
                  const username = this.ngRedux.getState().user.username;
                  authorizedRequest = request.clone({
                    headers: authorizedRequest.headers.set("X-Scai-Username", username)
                  });
                }
                return next.handle(authorizedRequest);
              } else {
                console.log('User not authenticated. Forcing signout and returning to login page.')
                this.authService.signOutUser()  // TODO check if this can be improved, if we can sign-out "gracefully", i.e. without throwing an error?
                  .then(() => {
                    console.log('Forced user signout completed.')
                  })
                  .catch((err) => console.log('An error occurred in forced user signout', err));
                  throwError(new Error('Not authorized'));
              }    
            })
          );
   }
}