import {
  PaginationParams,
  DataElement,
  ReferencePhrase,
  AddInstr,
  Doc,
  PhraseWord,
  CatMatch,
  ProcessRunStatusEnum,
  AnnotationElement,
  GrammarSpellingIssue,
  ReferenceIssue,
  AssociatedTextPhrase,
} from './common';
import { SelectItem, TreeNode } from 'primeng/api';
import { StreamName } from 'aws-sdk/clients/cognitosync';
import { stringList } from 'aws-sdk/clients/datapipeline';
import { Env } from './user.model';
import { RevRule } from './rev.model';
import { dateTimeRangeList } from 'aws-sdk/clients/health';

export class Catalog implements SelectItem {
  catId: number;
  catDescr: string;
  activeVersionId: number;
  productId: number;
  productDescr: string;
  typeCode: string;
  typeDescr: string;
  catVersionId: number;
  catVersions: Catalog[] = [];
  versionId: number;
  versionName: string;
  createDate: string;
  statusCode: string;
  statusDescr: string;
  statusDate: string;
  totDocCnt: number;
  attachedDocsList: DataElement[] = [];
  catAssetTypeList: DataElement[] = [];
  extSw: boolean;
  extCat: Catalog[] = [];
  refMatchTypeCode: string;
  langCode: string;
  langDescr: string;

  get hasPublished(): boolean {
    return (
      this.catVersions.find(
        (c) => c.statusCode == CatalogStatusEnum.PUBLISHED
      ) != null
    );
  }

  get label(): string {
    return this.catDescr;
  }

  get value() {
    return this;
  }
}

export class CatalogSearchParams extends PaginationParams {
  envId?: number;
  catIdName?: string;
  typeCode?: string;
  products?: DataElement[] = [];
  extSw?: boolean;

  constructor() {
    super();
  }
}

export class CatDoc extends Doc {
  versionId: number;
  fileName: string;
  createDate: string;
  lastVersionId: number;
  inCatVersionId: number;
  statusCode: string;
  statusDescr: string;
  progressPct: number;
  docType: string;
  histDocs: CatDoc[] = [];
  pendingCnt: number;
  approvedCnt: number;
  approvedVariantCnt: number;
  rejectedCnt: number;
  parentDocId: number;
  catId: number;
  catVersionId: number;
  statusDate: string;
  productId: number;
  runStatusCode: string;
  runStatusMessage: string;
  singlePhrasePageUrl: string;
  singlePhraseEl: string;
  singlePhrasePageNum: number;
  boundingBoxes: BoundingBox[] = [];
  attachedCardSw: boolean;
  assetType: DataElement;
  annotation: AnnotationElement;
  bulkSeq: number;
  origDocId: number;
  taggedSw: boolean;
  catDescr: string;
  catStatusCode: string;
  catStatusDescr: string;
  productDescr: string;
  createDocType: string;
  catalog: Catalog;
  taggedDocId: number;
  inddDocS3Key: string;
  inddCardS3Key: string;

  constructor() {
    super();
    this.docObjectType = 'IND';
  }

  get isProcessRunning(): boolean {
    return (
      this.runStatusCode == ProcessRunStatusEnum.RUNNING ||
      this.runStatusCode == ProcessRunStatusEnum.PENDING
    );
  }
}

export class CatEntriesSearchParams extends PaginationParams {
  text?: string;
  docs?: DataElement[];
  subTypeCodes?: string[];
  catEntryStatus?: DataElement;
  refStarterSw?: boolean;
  constructor() {
    super();
  }
}

export class CatImagesSearchParams extends PaginationParams {
  constructor() {
    super();
  }
}

export class AltRef {
  catRefAltId: number;
  txt: string;
  phrases: ReferencePhrase[] = [];
}

export class CatAltRefSearchParams extends PaginationParams {
  constructor() {
    super();
  }
}

export class CardSearchParams {
  shortModeSw: boolean;
  docId: number;
  cardId: number;
  catVersionId: number;
  lcId: number;
  title: string;
  dispatch: boolean = false;
}

export class CatDocsSearchParams extends PaginationParams {
  docIdName?: string;
  status?: DataElement;
  docId?: number;
  includeInactiveSw?: boolean;

  constructor() {
    super();
  }
}

export class CatPhraseType {
  typeCode: string;
  typeDescr: string;
  subTypes: CatPhraseSubType[] = [];
  original: string;

  saveOriginal() {
    this.original = JSON.stringify(this);
  }

  restoreOriginal() {
    let original = JSON.parse(this.original) as CatPhraseType;
    Object.keys(this).forEach((k) => {
      if (k !== 'original' && k !== 'subTypes') this[k] = original[k];
    });
  }

  isDirty(): boolean {
    let dirty = false;

    let original = JSON.parse(this.original) as CatPhraseType;
    Object.keys(this).forEach((k) => {
      if (k !== 'original' && k !== 'subTypes' && this[k] !== original[k])
        dirty = true;
    });

    return dirty;
  }
}

export class CatPhraseSubType {
  typeCode: string;
  typeDescr: string;
  subTypeCode: string;
  subTypeDescr: string;
  errorLevel: number;
  level0: number;
  level1: number;
  algKeyWords: string;
  original: string;

  saveOriginal() {
    this.original = JSON.stringify(this);
  }

  restoreOriginal() {
    let original = JSON.parse(this.original) as CatPhraseSubType;
    Object.keys(this).forEach((k) => {
      if (k !== 'original') this[k] = original[k];
    });
  }

  isDirty(): boolean {
    let dirty = false;

    let original = JSON.parse(this.original) as CatPhraseSubType;
    Object.keys(this).forEach((k) => {
      if (k !== 'original' && this[k] !== original[k]) dirty = true;
    });

    return dirty;
  }
}

export class CatPhraseTypeModel {
  types: CatPhraseType[] = [];
  treeTypes: TreeNode[] = [];
  treeTypesForInput: TreeNode[] = [];

  buildTree() {
    this.treeTypes = [];
    this.types.forEach((t) => {
      let node = {
        label: t.typeDescr,
        key: t.typeCode,
        value: t,
        data: {
          typeCode: t.typeCode,
          typeDescr: t.typeDescr,
          treeLevel: 0,
        },
      };

      if (t.subTypes.length > 0) {
        let children = [];
        t.subTypes.forEach((s) => {
          children.push({
            label: s.subTypeDescr,
            key: s.subTypeCode,
            value: s,
            data: {
              typeCode: s.subTypeCode,
              typeDescr: s.subTypeDescr,
              errorLevel: s.errorLevel,
              level0: s.level0,
              level1: s.level1,
              algKeyWords: s.algKeyWords,
              treeLevel: 1,
            },
          });
        });
        node['children'] = children;
      }

      this.treeTypes.push(node);
    });

    this.treeTypesForInput = JSON.parse(JSON.stringify(this.treeTypes));
    this.treeTypesForInput.forEach((n) => (n.selectable = false));
  }

  getSubTypeNode(
    tree: TreeNode[],
    typeCode: string,
    subTypeCode: string
  ): TreeNode {
    let parent = tree.find((n) => n.key == typeCode);
    if (parent) return parent.children.find((n) => n.key == subTypeCode);
    else return null;
  }

  getTreeBySelectedType(typeCode: string, subTypeCode: string): TreeNode[] {
    let tree = this.treeTypesForInput;
    tree.forEach((n) => {
      n.selectable = false;
      n.expanded = false;
      if (n.key == typeCode) {
        n.expanded = true;
      }
    });

    return tree;
  }
}

export class IndElement extends AnnotationElement {
  elementId: number;
  pageNum: number;
  statusCode: string;
  statusDescr: string;
  userStatus: DataElement;
  errorLevel: number;
  similarityCode: string;
  similarityDescr: string;
  similarityPct: number;
  addInstr: AddInstr[] = [];
  checkedSw: boolean;
  viewSw: boolean;
  catMatches: CatMatch[] = [];
  docs: CatDoc[] = [];
  catId: number;
  docId: number;
  sortOrder: number;
  typeCode: string;
  typeDescr: string;
  subTypeCode: string;
  subTypeDescr: string;
  manualSrcSw: boolean;
  original: string;
  catEntryStatus: DataElement;
  extRefs: ExtRef[] = [];
  variantOrigPhraseId?: number;

  figureType: string;
  figureTypeDescr: string;
  figureSubType: DataElement;

  get hasId(): boolean {
    return this.elementId !== undefined;
  }

  saveOriginal() {}

  restoreOriginal() {}

  isDirty(): boolean {
    return false;
  }
}

export class IndImage extends IndElement {
  imageId: number;
  parentImageId: number;
  imgUrl: string;
  imgS3Key: string;
  imageSimilarityEvent: string;
  imageSimilarityEventDesc: string;
  imageFindingEvents: string[] = [];
  imageActions: string[] = [];
  
  constructor() {
    super();
    this.annIdPrefix = 'img';
  }

  saveOriginal() {
    this.addInstr.forEach((i) => i.saveOriginal());
    this.original = JSON.stringify(this);
  }

  restoreOriginal() {
    let original = JSON.parse(this.original) as IndPhrase;
    this.typeCode = original.typeCode;
    this.typeDescr = original.typeDescr;
    this.subTypeCode = original.subTypeCode;
    this.subTypeDescr = original.subTypeDescr;
    this.figureSubType = original.figureSubType;

    this.addInstr = original.addInstr.map((r) => {
      let newInstr = new AddInstr();
      Object.assign(newInstr, r);
      return newInstr;
    });
  }

  isDirty(): boolean {
    let original = JSON.parse(this.original) as IndPhrase;
    let addInstrCntDiff = this.addInstr.length !== original.addInstr.length;
    let instrDiff;
    this.addInstr.forEach((r) => {
      let orig = original.addInstr.find((o) => o.id == r.id);
      if (!orig) {
        instrDiff = true;
      } else if (r.isDirty()) {
        instrDiff = true;
      }
    });
    let typeDiff =
      this.typeCode != original.typeCode ||
      this.subTypeCode != original.subTypeCode ||
      this.figureSubType?.id != original.figureSubType?.id;
    return addInstrCntDiff || instrDiff || typeDiff;
  }
}

export class IndPhrase extends IndElement {
  phraseId: number;
  phraseText: string;
  isRefSw: boolean;
  references: ReferencePhrase[] = [];
  associatedText: AssociatedTextPhrase[] = [];
  deletedReferences: ReferencePhrase[] = [];
  deletedAssociatedText: AssociatedTextPhrase[] = [];
  referencedBy: ReferencePhrase[] = [];
  associatedBy: AssociatedTextPhrase[] = [];
  phraseWords: PhraseWord[] = [];
  grammarSpellingIssues: GrammarSpellingIssue[];
  referenceIssues: ReferenceIssue[];
  mergeOptions: MergeLine[] = [];
  inCardCheckedSw: boolean;
  lastBBId: number;
  docId: number;
  transTxt: string;
  dirtySw: boolean;
  phraseOrder: number;
  phraseContentType: string;
  phraseContentTypeDesc: string;
  phraseSimilarityEvent: string;
  phraseSimilarityEventDesc: string;
  phraseFindingEvents: string[] = [];
  phraseActions: string[] = [];
  phraseFindingCategoryDescs: [string, string][] = []

  saveOriginal() {
    this.addInstr.forEach((i) => i.saveOriginal());
    this.references.forEach((i) => i.saveOriginal());
    this.associatedText.forEach((i) => i.saveOriginal());
    this.original = JSON.stringify(this);
  }

  restoreOriginal() {
    let original = JSON.parse(this.original) as IndPhrase;
    this.typeCode = original.typeCode;
    this.typeDescr = original.typeDescr;
    this.subTypeCode = original.subTypeCode;
    this.subTypeDescr = original.subTypeDescr;
    this.catEntryStatus = original.catEntryStatus;

    this.references = original.references.map((r) => {
      let newRef = new ReferencePhrase();
      Object.assign(newRef, r);
      return newRef;
    });

    this.associatedText = original.associatedText.map((r) => {
      let newAssocText = new AssociatedTextPhrase();
      Object.assign(newAssocText, r);
      return newAssocText;
    });

    this.addInstr = original.addInstr.map((r) => {
      let newInstr = new AddInstr();
      Object.assign(newInstr, r);
      return newInstr;
    });
  }

  isDirty(): boolean {
    let original = JSON.parse(this.original) as IndPhrase;
    let phraseDiff = this.phraseText != original.phraseText;
    let statusDiff = this.catEntryStatus?.id != original.catEntryStatus?.id;
    let typeDiff =
      this.typeCode != original.typeCode ||
      this.subTypeCode != original.subTypeCode;

    let refCntDiff = this.references.length !== original.references.length;
    let refDiff;
    this.references.forEach((r) => {
      let origRef = original.references.find(
        (o) => o.refPhraseId == r.refPhraseId
      );
      if (!origRef) {
        refDiff = true;
      } else if (r.isDirty()) {
        refDiff = true;
      }
    });

    let assocTextCntDiff = this.associatedText.length !== original.associatedText.length;
    let assocTextDiff;
    this.associatedText.forEach((r) => {
      let origAssocText = original.associatedText.find(
        (o) => o.assocPhraseId == r.assocPhraseId
      );
      if (!origAssocText) {
        assocTextDiff = true;
      }
    });

    let addInstrCntDiff = this.addInstr.length !== original.addInstr.length;
    let instrDiff;
    this.addInstr.forEach((r) => {
      let orig = original.addInstr.find((o) => o.id == r.id);
      if (!orig) {
        instrDiff = true;
      } else if (r.isDirty()) {
        instrDiff = true;
      }
    });

    return (
      phraseDiff ||
      typeDiff ||
      refCntDiff ||
      refDiff ||
      assocTextCntDiff ||
      assocTextDiff ||
      addInstrCntDiff ||
      instrDiff ||
      statusDiff
    );
  }
}

export class IndPhraseSearchParams {
  phrase: string;
  matchStatus: DataElement;
  phraseStatus: DataElement;
  contentType: DataElement;
  matchType: DataElement;
  subTypeList: string[] = [];
}

export class MergeLine {
  id: number;
  text: string;
  inUseSw: boolean;
  phraseId: number;
  phraseWords: PhraseWord[] = [];
  displayWordsSw: boolean;
  uniqueId: string;

  get label(): string {
    return this.text;
  }

  get value() {
    return this;
  }
}

export class Card {
  cardId: number;
  type: DataElement;
  title: string;
  createDate: string;
  cardPhrases: IndPhrase[] = [];
  cardDocs: CatDoc[] = [];
  mcType: DataElement;
  mcSubType: DataElement;
  lcCardType: DataElement;
  lcId: number;
  matchedCardId: number;
  matchStatusCode: string;
  matchStatusDescr: string;
  matchedCard: Card;
  srcType: string;
  transLang: DataElement[] = [];
  sortOrder: number;
  cardStatus: string;
  original: string;

  saveOriginal() {
    this.original = JSON.stringify(this);
    this.cardPhrases.forEach((i) => i.saveOriginal());
  }

  restoreOriginal() {
    let original = JSON.parse(this.original) as Card;
    Object.keys(this).forEach((k) => {
      if (k !== 'original' && k !== 'cardPhrases') this[k] = original[k];
    });

    this.cardPhrases = original.cardPhrases.map((r) => {
      let newRef = new IndPhrase();
      Object.assign(newRef, r);
      return newRef;
    });
  }

  isDirty(): boolean {
    if (this.original == null) return false;
    let original = JSON.parse(this.original) as Card;
    let titleDiff = this.title != original.title;
    let typeDiff = this.type.id != original.type.id;
    let mcTypeDiff = this.mcType?.id != original.mcType?.id;
    let mcSubTypeDiff = this.mcSubType?.id != original.mcSubType?.id;
    let phraseCntDiff = this.cardPhrases.length !== original.cardPhrases.length;
    let phraseDiff;

    this.cardPhrases.forEach((r) => {
      let origRef = original.cardPhrases.find(
        (o) => o.phraseId == r.phraseId && o.sortOrder == r.sortOrder
      );
      if (!origRef) {
        phraseDiff = true;
      }
    });

    original.cardPhrases.forEach((r) => {
      let origRef = this.cardPhrases.find(
        (o) => o.phraseId == r.phraseId && o.sortOrder == r.sortOrder
      );
      if (!origRef) {
        phraseDiff = true;
      }
    });

    return (
      titleDiff ||
      typeDiff ||
      phraseCntDiff ||
      phraseDiff ||
      mcTypeDiff ||
      mcSubTypeDiff
    );
  }
}

export class BoundingBox {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  elementId: string;
  boxId: number;
  boxType: DataElement;
  card: Card;
  descr: string;
  pageNum: number;
  displayPageNum: number;
  pageUrl: string;
  imgUrl: string;
  s3Key: string;
  indPhrases: IndPhrase[];
  revPhrases: RevRule[];
  pageWidth: number;
  pageHeight: number;
  deleting: boolean;
  elements: HTMLElement[] = [];
  mcBoxType: DataElement;
  blockRules: string;
  useForPrecheckSw: boolean;
  dirtySw: boolean;
}

export class CatDocFileWrapper {
  file: File;
  docName: string;
  parentDoc: CatDoc;
  uploading: boolean;
  docS3Key: string;
  docFileName: string;
  doc: CatDoc;
  finished: boolean;
}

export class McSubType {
  subType: DataElement;
  mcTypeCode: string;
}

export class McBoxType {
  mcTypeCode: string;
  mcSubTypeCode: string;
  mcBoxType: DataElement;
  blockRules: string;
}

export class CardAssetTemplate {
  id: number;
  name: string;
  descr: string;
  assetType: DataElement;
  url: string;
  previewUrl: string;
  cards: Card[] = [];
}

export class ExtRef {
  extRefId: number;
  srcDocId: number;
  docName: string;
  srcPhraseId: number;
  phraseDispWords: PhraseWord[] = [];
  pageNum: number;
  type: string;
  extPageNum: number;
  extDocId: number;
  extType: string;
  extPhraseId: number;
  extPhraseDispWords: PhraseWord[] = [];
  extDocName: string;
  extCatDescr: string;
  extCatVersionId: number;
  ann: AnnotationElement;
  extAnn: AnnotationElement;
}

export class CatExtRefSearchParams {
  docId?: number;
  extDocId?: number;
  phraseText?: string;
  extPhraseText?: string;

  constructor() {}
}

export class CatDocState {
  indDocPhrases?: IndPhrase[] = [];
  indDocImages?: IndImage[] = [];
  curCatDocPageNum?: number = 1;
  catPhrasesSearchParams?: IndPhraseSearchParams = null;
  catDocBoundingBoxes?: BoundingBox[] = [];
  catDocRefOptions?: IndPhrase[] = [];
  catDocPageLines?: MergeLine[] = [];
  catDocCards?: Card[] = [];
  curCatDoc?: CatDoc = null;
}

export class CatDocStateTagEnum {
  static PRIME: string = 'PRIME';
  static SEC: string = 'SEC';
}

/*** ENUMS */

export class CatalogStatusEnum {
  public static DRAFT: string = 'DRAFT';
  public static PUBLISHED: string = 'PUBLISHED';
  public static HIST: string = 'HIST';
  public static CLOSED: string = 'CLOSED';
}

export class CatDocStatusEnum {
  public static DRAFT: string = 'DRAFT';
  public static DETACHED: string = 'DETACHED';
  public static HIST: string = 'HIST';
  public static ADDED_TO_CATALOG: string = 'ADDED_TO_CATALOG';
  public static INACTIVE: string = 'INACTIVE';
}

export class CatPhraseStatusEnum {
  public static PENDING: string = 'PENDING';
  public static APPROVE: string = 'APPROVE';
  public static APPROVE_VAR: string = 'APPROVE_VARIANT';
  public static REJECT: string = 'REJECT';
}
