import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { PdfViewerComponent } from 'src/app/shared/components/pdf-viewer/pdf-viewer.component';
import {
  BoundingBox,
  Card,
  CatDoc,
  CatDocStateTagEnum,
} from 'src/app/shared/models/cat.model';
import { AppState, actionsList } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { IntegrationService } from 'src/app/shared/services/integration.service';

@Component({
  selector: 'cat-doc-cards',
  templateUrl: './cat-doc-cards.component.html',
  styleUrls: ['./cat-doc-cards.component.scss'],
})
export class CatDocCardsComponent implements OnInit {
  @Input() curBox: BoundingBox;
  @Input() lcSw: boolean;
  @Input() stateTag: CatDocStateTagEnum = CatDocStateTagEnum.PRIME;

  @Output() onClose = new EventEmitter();
  @Output() onUpdate = new EventEmitter();

  tabItems: MenuItem[] = [];
  activeItem: MenuItem;
  curCard: Card;

  loading: boolean;

  get docCards(): Card[] {
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.catDocCards
      : this.ngRedux.getState().curCatDocStateSec.catDocCards;
  }

  get curCatDoc(): CatDoc {
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.curCatDoc
      : this.ngRedux.getState().curCatDocStateSec.curCatDoc;
  }

  get curCatDocPageNum(): number {
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.curCatDocPageNum
      : this.ngRedux.getState().curCatDocStateSec.curCatDocPageNum;
  }

  get allowEditCard(): boolean {
    return this.stateTag == CatDocStateTagEnum.PRIME;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private sharedService: SharedService,
    private integrationService: IntegrationService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.curBox) {
      const element = document.getElementById(this.curBox.boxId.toString());
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }, 500);
      }
    }
  }

  initTabs() {
    this.tabItems = [];
    this.docCards.forEach((c) =>
      this.tabItems.push({
        label: c.title,
        id: c.cardId.toString(),
        command: () => {
          this.curCard = c;
        },
      })
    );

    if (this.docCards && this.docCards.length > 0) {
      this.activeItem = this.tabItems[0];
      this.curCard = this.docCards[0];
    }
  }

  close() {
    this.onClose.emit();
  }

  deleteBox(box: BoundingBox, card: Card) {
    this.loading = true;
    this.catService
      .updBoundingBox(this.curCatDocPageNum, this.curCatDoc, box, true)
      .subscribe(
        (box) => {
          this.loading = false;
          card.cardDocs[0].boundingBoxes.splice(
            card.cardDocs[0].boundingBoxes.indexOf(box),
            1
          );
          this.onUpdate.emit();
        },
        () => (this.loading = false)
      );
  }

  toggleStatus(card: Card): void {
    this.loading = true;
    if (card.cardStatus === 'Approved') {
      card.cardStatus = 'Draft';
    } else {
      card.cardStatus = 'Approved';
    }
    this.integrationService.setBlockStatus(card.cardId, card.cardStatus).subscribe(
      (data) => {
        this.loading = false;
        this.onUpdate.emit();
        this.ngRedux.dispatch({
          type: actionsList.RELOAD_CAT_DOC_PAGE_REQ,
          data: false,
        });
      },
      (err) => (this.loading = false)
    );
  }
}
