<modal-popup
  [flexible]="true"
  [focusOnOpen]="false"
  [isOpen]="true"
  (onClose)="close()"
  [title]="'translate.rev.addRevDoc'"
>
  <div class="container">
    <!--doc name-->
    <input-wrap
      label="{{ 'translate.general.docName' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <input
        type="text"
        [(ngModel)]="docName"
        class="t-input"
        [disabled]="parentDoc"
      />
    </input-wrap>

    <!--product-->
    <input-wrap
      [loading]="productList && productList.loading"
      label="{{ 'translate.general.productDescr' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <p-dropdown
        [options]="productList"
        optionLabel="label"
        [(ngModel)]="product"
        class="l-cell-spread-x"
        [resetFilterOnHide]="true"
        [filterPlaceholder]="'translate.general.search' | translate"
        [placeholder]="'translate.general.select' | translate"
        [filter]="true"
        appendTo="body"
        baseZIndex="100"
        (onChange)="onProductChange()"
        [disabled]="parentDoc"
      >
      </p-dropdown>
    </input-wrap>

    <!--catalog-->
    <input-wrap
      [loading]="catalogList && catalogList.loading"
      label="{{ 'translate.general.catalog' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <p-dropdown
        [options]="catalogList"
        optionLabel="catDescr"
        [disabled]="!product"
        [(ngModel)]="catalog"
        class="l-cell-spread-x"
        [resetFilterOnHide]="true"
        [filterPlaceholder]="'translate.general.search' | translate"
        [placeholder]="'translate.general.select' | translate"
        [filter]="true"
        appendTo="body"
        baseZIndex="100"
      >
        <ng-template let-el pTemplate="item">
          <div class="l-align-spaceBetween">
            <p>{{ el.catDescr }}</p>
            <!--
            <span class="t-footnote"
              >{{ el.catTypeDescr }} (version {{ el.lastVersionId }})</span
            >
            -->
            <span class="t-footnote">{{ el.catTypeDescr }}</span>
          </div>
        </ng-template>
      </p-dropdown>
    </input-wrap>

    <!--asset type-->
    <input-wrap
      label="{{ 'translate.general.assetType' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <p-dropdown
        [options]="assetTypes"
        optionLabel="text"
        [(ngModel)]="assetType"
        class="l-cell-spread-x"
        [placeholder]="'translate.general.select' | translate"
        appendTo="body"
        baseZIndex="100"
        [disabled]="!catalog"
      >
      </p-dropdown>
    </input-wrap>

    <!--file upload-->
    <div class="upload-container">
      <input-wrap
        *ngIf="!isUploading && !docFileName"
        [customValidation]="isValidFile"
        [customValidationWarn]="
          'translate.validation.revDocFileTypes' | translate
        "
        [cssClass]="'l-ta-center input-wrap-hasFileSpread'"
      >
        <input
          id="uploadFileInput"
          (change)="uploadFile($event)"
          class="t-fileInput-spread"
          type="file"
          accept=".pdf,.zip"
        />

        <button class="t-button">
          {{ "translate.general.selectFile" | translate }}
        </button>
      </input-wrap>
      <spinner *ngIf="isUploading" class="l-ta-center"></spinner>
      <p *ngIf="docFileName">
        <span>{{ fileName }}</span>
        <a (click)="removeFile()">
          ({{ "translate.general.remove" | translate }})</a
        >
      </p>
    </div>

    <div class="actions">
      <button class="t-button-outline" (click)="close()">
        {{ "translate.general.cancel" | translate }}
      </button>
      &nbsp;
      <button
        class="t-button"
        [disabled]="!enableSubmit || saving"
        (click)="createDoc()"
      >
        {{ "translate.general.ok" | translate }}
      </button>
    </div>
  </div>

  <loader *ngIf="saving"></loader>
</modal-popup>
