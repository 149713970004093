<div>
  <section class="page-header sticky responsive">
    <loader *ngIf="loading"></loader>
    <div class="title-container">
      <div class="title">
        <h1>
          <a class="icon" (click)="goBack()"
            ><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon
          ></a>
          &nbsp;
          {{ doc.docName }}

          <div [ngClass]="['status-tag', 'rev-status-tag', doc.statusCode]">
            {{ doc.statusDescr }}
          </div>
        </h1>
      </div>

      <div
        [style.visibility]="showBarStatusChart ? 'visible' : 'hidden'"
        class="canvas-bar-status-container"
      >
        <span class="progress-pct">{{ doc.progressPct | percent }}</span>
        <canvas id="chart-bar-status-cnv" width="400px" height="80px"></canvas>
      </div>

      <div *ngIf="!showBarStatusChart" class="l-align-center buttons">
        <spinner *ngIf="downloadingFile"></spinner>
        <button
          (click)="downloadAnnotatedDoc()"
          class="t-button"
          [disabled]="loading || downloadingFile"
        >
          {{ "translate.rev.downloadAnnotatedDoc" | translate }}
        </button>
        &nbsp;

        <button
          *ngIf="allowComplete"
          (click)="completeDoc()"
          class="t-button"
          [disabled]="loading"
        >
          {{ "translate.rev.completeDoc" | translate }}
        </button>

        &nbsp;
        <button
          *ngIf="allowFinalize"
          (click)="finalizeDoc()"
          class="t-button"
          [disabled]="loading"
        >
          {{ "translate.rev.finalizeDoc" | translate }}
        </button>

        &nbsp;
        <button
          *ngIf="allowReopen"
          (click)="reopenDoc()"
          class="t-button-outline"
          [disabled]="loading"
        >
          {{ "translate.rev.reopenDoc" | translate }}
        </button>

        &nbsp;
        <button
          *ngIf="allowInactivate"
          (click)="inactivateDoc()"
          class="t-button-outline"
          [disabled]="loading"
        >
          {{ "translate.rev.inactivateDoc" | translate }}
        </button>
      </div>
    </div>
  </section>

  <section class="l-align-spaceBetween">
    <div class="l-flex">
      <data-box
        [title]="'translate.rev.docDetails'"
        class="data-widget"
        [data]="doc"
        [displayFilter]="[
          'docId',
          'createDateFormatted',
          'catDescr',
          'actCatVersionId'
        ]"
        [templateRefs]="{
          actCatVersionId: catalogVersion
        }"
        [columns]="1"
        [belowData]="showMore"
      ></data-box>
      <ng-template #showMore>
        <div class="l-align-spaceBetween">
          <!-- <loader *ngIf="cardsLoading"></loader>
          <div *ngIf="docCards && docCards.length">
            <div class="t-row--med">
              <a (click)="docCardsOpenSw = true"
                >{{ "translate.general.cards" | translate }} ({{
                  docCards.length
                }})</a
              >
            </div>
          </div> -->
          <div class="t-small">
            <a
              *ngIf="!showMoreDocDetailsSw"
              (click)="showMoreDocDetailsSw = true"
              >{{ "translate.general.showMore" | translate }}</a
            >
            <a
              *ngIf="showMoreDocDetailsSw"
              (click)="showMoreDocDetailsSw = false"
              >{{ "translate.general.showLess" | translate }}</a
            >
          </div>
        </div>
      </ng-template>

      <ng-template #catalogVersion>
        <div>
          {{ doc.catVersionId }}
          <fa-icon
            *ngIf="doc.newCatVersionSw"
            class="c1"
            [icon]="['fas', 'exclamation-circle']"
            [tooltip]="'translate.rev.newCatVersionAvailable' | translate"
          ></fa-icon>
        </div>
      </ng-template>

      <data-box
        *ngIf="showMoreDocDetailsSw"
        class="data-widget"
        [showEmptyTitle]="true"
        [data]="doc"
        [displayFilter]="[
          'versionId',
          'fileName',
          'uploadUserDescr',
          'assetType',
          'productDescr'
        ]"
        [columns]="1"
        [templateRefs]="{
          assetType: assetType
        }"
      ></data-box>
    </div>

    <div class="l-flex">
      <data-box
        [title]="'translate.general.statusDescr'"
        [data]="doc"
        class="data-widget"
        [columns]="2"
        [displayFilter]="displayFilterOptions"
        [templateRefs]="{
          failedCnt: failedCnt,
          markedCnt: markedCnt
        }"
      ></data-box>

      <section class="t-widget">
        <h2 class="t-widgetTitle">
          {{ "translate.rev.reviewReadinessScore" | translate }}
        </h2>
        <div class="canvas-status-container">
          <canvas id="chart-status-cnv" width="130px" height="130px"></canvas>
        </div>
      </section>
    </div>
  </section>

  <ng-template #assetType>
    <span *ngIf="doc.assetType"> {{ doc.assetType.text }} </span>
  </ng-template>

  <ng-template #markedCnt>
    <p [class.c-reject]="doc.markedCnt > 0" [class.fw-bold]="doc.markedCnt > 0">
      {{ doc.markedCnt }}
    </p>
  </ng-template>

  <ng-template #failedCnt>
    <p [class.c-reject]="doc.failedCnt > 0" [class.fw-bold]="doc.failedCnt > 0">
      {{ doc.failedCnt }}
    </p>
  </ng-template>

  <tabs>
    <tab-single
      tabTitle="{{ 'translate.cat.docTab' | translate }}"
      [tabId]="tabIdList.REV_DOC__VIEWER"
    >
      <section class="filter-bar l-align-spaceBetween">
        <div class="filters">
          <!--phrase-->
          <input-wrap class="t-input-search-wrap">
            <input
              class="t-input"
              type="text"
              [(ngModel)]="filterPhrase"
              placeholder="{{ 'translate.general.search' | translate }}"
              (keypress)="onFilterKeyPress($event)"
            />
          </input-wrap>

          <!--status-->
          <input-wrap [loading]="filteredRuleStatusList && filteredRuleStatusList.loading">
            <p-dropdown
              [options]="filteredRuleStatusList"
              optionLabel="label"
              [(ngModel)]="filterStatus"
              [resetFilterOnHide]="true"
              [filterPlaceholder]="'translate.general.search' | translate"
              [placeholder]="'translate.general.statusDescr' | translate"
              [filter]="true"
              (onChange)="onFieldValueChanged($event)"
            >
            </p-dropdown>
          </input-wrap>

          <!--content type-->
          <input-wrap [loading]="contentTypeList && contentTypeList.loading">
            <p-dropdown
              [options]="contentTypeList"
              optionLabel="label"
              [(ngModel)]="filterContentType"
              [resetFilterOnHide]="true"
              [filterPlaceholder]="'translate.general.search' | translate"
              [placeholder]="'translate.general.contentTypeDescr' | translate"
              [filter]="true"
              (onChange)="onFieldValueChanged($event)"
            >
            </p-dropdown>
          </input-wrap>

          <!--match type-->
          <input-wrap [loading]="matchTypeList && matchTypeList.loading">
            <p-dropdown
              [options]="matchTypeList"
              optionLabel="label"
              [(ngModel)]="filterMatchType"
              [resetFilterOnHide]="true"
              [filterPlaceholder]="'translate.general.search' | translate"
              [placeholder]="'translate.general.matchTypeDescr' | translate"
              [filter]="true"
              (onChange)="onFieldValueChanged($event)"
            >
            </p-dropdown>
          </input-wrap>

          <a *ngIf="displayClearFilter" (click)="clearFilter()">{{
            "translate.general.clear" | translate
          }}</a>

          <!-- Whitelist spelling issues -->
          &nbsp;

          <button
            class="t-button"
            [disabled]="loading"
            (click)="docSpellcheckWhitelistingOpenSw = true"
          >
            {{ "translate.general.spellingCheckIgnored" | translate }}
          </button>
        </div>

        <div class="actions">
          <checkbox-wrap
            *ngIf="editable"
            class="checkbox"
            [label]="'translate.general.selectAll' | translate"
          >
            <input
              type="checkbox"
              [(ngModel)]="toggleAllSw"
              (ngModelChange)="onToggleSelectAll()"
            />
          </checkbox-wrap>
          &nbsp;

          <button
            *ngIf="editable"
            class="t-button-outline"
            [disabled]="selectedRules.length == 0 || loading"
            (click)="markSelected()"
          >
            {{ "translate.rev.markForChangesSelected" | translate }}
            ({{ selectedRules.length }})
          </button>
          &nbsp;
          <button
            *ngIf="editable"
            class="t-button-outline"
            [disabled]="selectedRules.length == 0 || loading"
            (click)="dismissSelected()"
          >
            {{ "translate.rev.dismissSelected" | translate }}
            ({{ selectedRules.length }})
          </button>
          &nbsp;
          <button
            *ngIf="doc.inddS3Key"
            class="t-button-outline"
            (click)="downloadIndd()"
            [disabled]="downloadingZip"
          >
            <fa-icon [icon]="['fas', 'download']"></fa-icon>&nbsp; .indd
          </button>
        </div>
      </section>

      <div class="content-container">
        <div class="tbl-container">
          <rev-doc-rules-list
            #revRulesList
            (onToggleRuleView)="onToggleRuleView($event)"
            (onShowRuleView)="showRuleAnnotation($event)"
            (onHideRuleView)="hideRuleAnnotation($event)"
          ></rev-doc-rules-list>
        </div>
        <div class="pdf-container">
          <pdf-viewer
            #docViewer
            [doc]="doc"
            [initialPage]="curPage"
            [singlePage]="true"
            (onPageChanged)="onPageChanged($event)"
            (onToggleAnnotations)="onToggleAnnotations($event)"
            (onToggleImageAnnotations)="onToggleImageAnnotations($event)"
            (onIframeLoaded)="onDocIframeLoaded()"
            (onAnnMouseEnter)="onAnnMouseEnter($event)"
            (onAnnMouseLeave)="onAnnMouseLeave($event)"
            (onAnnClicked)="onAnnClicked($event)"
            [ignoreMouseEvents]="panelResizing"
            [displayToggleAnnotations]="!pageLoading"
          ></pdf-viewer>
        </div>
      </div>
    </tab-single>

    <tab-single
      [disabled]="!(docCards && docCards.length && docFrameLoaded)"
      tabTitle="{{ 'translate.general.cards' | translate }}  ({{
        docCards.length
      }})"
      [tabId]="tabIdList.REV_DOC__CARDS"
    >
      <rev-doc-cards #revDocCards (onClose)="docCardsOpenSw = false">
      </rev-doc-cards>
    </tab-single>
  </tabs>
</div>

<!-- <rev-doc-cards
  #revDocCards
  *ngIf="docCardsOpenSw"
  (onClose)="docCardsOpenSw = false"
>
</rev-doc-cards> -->

<doc-spellcheck-whitelisting
  *ngIf="docSpellcheckWhitelistingOpenSw"
  (onClose)="docSpellcheckWhitelistingOpenSw = false"
  (onSave)="onUpdateSpellingWhitelist()"
  [docId]="doc.docId"
  [docMode]="'rev'"
>
</doc-spellcheck-whitelisting>