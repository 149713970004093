import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CatDoc, Catalog } from 'src/app/shared/models/cat.model';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { UploadService } from 'src/app/shared/services/upload.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { DataElement, FileUpload } from 'src/app/shared/models/common';

@Component({
  selector: 'create-cat-doc',
  templateUrl: './create-cat-doc.component.html',
  styleUrls: ['./create-cat-doc.component.scss'],
})
export class CreateCatDocComponent implements OnInit {
  @Input() parentDoc: CatDoc;
  @Output() onClose = new EventEmitter();
  @Output() onDocCreated = new EventEmitter<CatDoc>();

  docName: string;
  assetType: DataElement;
  isUploading: boolean;
  isValidFile: boolean;
  docS3Key: string;
  docFileName: string;
  fileName: string;
  saving: boolean;

  get enableSubmit(): boolean {
    return this.docName != null && this.assetType != null && this.docFileName != null;
  }

  get catalog(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  get assetTypes(): DataElement[] {
    return this.catalog.catAssetTypeList
      .sort((a, b) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
      });
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private uploadService: UploadService,
    private authService: AuthService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    if (this.parentDoc) {
      this.docName = this.parentDoc.docName;
    }
  }

  close() {
    this.onClose.emit();
  }

  uploadFile($event) {
    const fileData: File = $event.target.files[0];
    if (!fileData) return;
    this.isValidFile = undefined;
    this.docFileName = null;
    this.fileName = null;

    this.isValidFile = this.uploadService.validateFile(
      fileData,
      'pdf,zip'
      //SharedService.revDocFileTypes
    );
    if (!this.isValidFile) return;

    this.isUploading = true;

    const file: FileUpload = new FileUpload();
    file.fileType = this.uploadService.getFileType(fileData);
    this.fileName = fileData.name;

    (async () => {
      await this.uploadService.uploadFile(fileData);
      file.file = fileData;

      this.docFileName = null;

      this.uploadService
        .getPresignedUrl(file, this.authService.getPresignedUrl)
        .subscribe(
          (data) => {
            this.uploadService.putFile(file, data).subscribe(
              (res) => {
                this.docFileName = file.file.name;
                console.log(file.fileType);
                // if (file.fileType.toLowerCase() == '.zip') {
                //   this.uploadService
                //     .loadInddFile(
                //       this.ngRedux.getState().curEnv.tmpS3Bucket,
                //       data.s3Key
                //     )
                //     .subscribe(
                //       (pdfKey) => {
                //         this.docS3Key = pdfKey;
                //         this.isUploading = false;
                //       },
                //       (err) => {
                //         this.removeFile();
                //         this.sharedService.alertError(err);
                //         this.isUploading = false;
                //       }
                //     );
                // } else {
                this.docS3Key = data.s3Key;
                this.isUploading = false;
                //}
              },
              (err) => {
                this.sharedService.alertError(err);
                this.isUploading = false;
              }
            );
          },
          (err) => {
            this.sharedService.alertError(err);
            this.isUploading = false;
          }
        );
    })();

    $event.target.value = '';
  }

  removeFile() {
    this.docFileName = null;
    this.fileName = null;
  }

  createDoc() {
    this.saving = true;

    let doc = new CatDoc();
    doc.docName = this.docName;
    if (this.parentDoc) {
      doc.parentDocId = this.parentDoc.docId;
    }
    doc.docName = this.docName;
    doc.catId = this.catalog.catId;
    doc.catVersionId = this.catalog.catVersionId;
    doc.docS3Key = this.docS3Key;
    doc.fileName = this.docFileName;
    doc.productId = this.catalog.productId;
    doc.assetType = this.assetType;

    var encryptSw = !doc.fileName.toLocaleLowerCase().endsWith('.zip');

    this.catService.createIndDoc(doc).subscribe(
      (savedDoc) => {
        if (encryptSw) {
          this.uploadService
            .encryptFile(
              savedDoc.origDocId,
              'IND',
              this.ngRedux.getState().curEnv.tmpS3Bucket,
              this.ngRedux.getState().curEnv.docS3Bucket,
              doc.docS3Key
            )
            .subscribe(
              (res) => {
                this.catService.processIndDocAlg(savedDoc.runNum).subscribe();
                this.saving = false;
                this.onDocCreated.emit(savedDoc);
              },
              (err) => {
                this.sharedService.alertError(err);
                this.saving = false;
              }
            );
        } else {
          this.catService.processIndDocAlg(savedDoc.runNum).subscribe();
          this.saving = false;
          this.onDocCreated.emit(savedDoc);
        }
      },

      (err) => (this.saving = false)
    );
  }
}
