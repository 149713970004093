<div *ngIf="doc">
  <section class="page-header">
    <loader *ngIf="loading"></loader>
    <div class="title-container">
      <div class="title">
        <div *ngIf="displayCatInfo" class="breadcrumbs">
          <!-- {{ curCat.catDescr }} -->
          <div [ngClass]="['status-tag', 'cat-status-tag', doc.statusCode]">
            {{ curCat.statusDescr }}
          </div>
        </div>

        <h1>
          <a class="icon" (click)="goBack()"
            ><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon
          ></a>
          &nbsp;
          {{ doc.docName }}
        </h1>
      </div>

      <div *ngIf="displayDocActions" class="l-align-center">
        <div *ngIf="editable">
          <button
            (click)="approveAllPages()"
            class="t-button-outline"
            [disabled]="loading"
          >
            {{ "translate.cat.approveAllPages" | translate }}
          </button>

          &nbsp;
        </div>

        <button
          *ngIf="allowAddToCatalog"
          (click)="onAddToCatalog()"
          class="t-button"
          [disabled]="loading"
        >
          {{ "translate.cat.addToCatalog" | translate }}
        </button>

        <button
          *ngIf="allowDetach"
          (click)="detach()"
          class="t-button"
          [disabled]="loading"
        >
          {{ "translate.cat.detach" | translate }}
        </button>

        <button
          *ngIf="allowReopen"
          (click)="reopen()"
          class="t-button"
          [disabled]="loading"
        >
          {{ "translate.cat.reopen" | translate }}
        </button>

        &nbsp; &nbsp;
        <button
          *ngIf="allowInactivate"
          (click)="inactivateDoc()"
          class="t-button-outline"
          [disabled]="loading"
        >
          {{ "translate.rev.inactivateDoc" | translate }}
        </button>
      </div>
    </div>
  </section>

  <section class="l-align-spaceBetween">
    <data-box
      [title]="'translate.cat.docDetails'"
      class="data-widget"
      [data]="doc"
      [displayFilter]="[
        'docId',
        'docName',
        'versionId',
        'fileName',
        'assetType',
        'createDate'
      ]"
      [templateRefs]="{
        assetType: assetType
      }"
      [columns]="1"
      [belowData]="modularContent"
    ></data-box>

    <ng-template #assetType>
      <span *ngIf="doc.assetType"> {{ doc.assetType.text }} </span>
    </ng-template>

    <ng-template #modularContent>
      <!-- <ng-container *ngIf="docCards && docCards.length">
        <div class="t-row--med"></div>
        <a (click)="curBoundingBox = null; docCardsOpenSw = true"
          >{{ "translate.general.cards" | translate }} ({{
            docCards.length
          }})</a
        >
      </ng-container> -->
    </ng-template>

    <div *ngIf="!mode && showMatchSw" class="l-flex">
      <data-box
        [title]="'translate.general.statusDescr'"
        class="data-widget-sm"
        [data]="doc"
        [columns]="1"
        [displayFilter]="[
          'statusDescr',
          'pendingCnt',
          'approvedCnt',
          'approvedVariantCnt',
          'rejectedCnt'
        ]"
        [templateRefs]="{
          statusDescr: statusDescr
        }"
      ></data-box>

      <section class="t-widget">
        <div class="canvas-status-container">
          <div class="canvas-status-container">
            <canvas id="chart-status-cnv" width="80px" height="80px"></canvas>
          </div>
        </div>
      </section>
    </div>

    <ng-template #statusDescr>
      <p
        class="fw-bold"
        [class.c-success]="doc.statusCode == 'ADDED_TO_CATALOG'"
      >
        {{ doc.statusDescr }}
      </p>
    </ng-template>
  </section>

  <tabs>
    <tab-single
      tabTitle="{{ 'translate.cat.docTab' | translate }}"
      [tabId]="tabIdList.CAT_DOC__VIEWER"
    >
      <section class="filter-bar l-align-spaceBetween">
        <div class="filters">
          <!--phrase-->
          <input-wrap class="t-input-search-wrap">
            <input
              class="t-input"
              type="text"
              [(ngModel)]="filterPhrase"
              placeholder="{{ 'translate.general.search' | translate }}"
              (keypress)="onFilterKeyPress($event)"
            />
          </input-wrap>

          <!--type-->
          <input-wrap>
            <button
              class="t-button t-button-search"
              (click)="op.toggle($event)"
            >
              {{ "translate.general.phraseType" | translate }}
              <span *ngIf="filterSubTypes"
                >({{ filterSubTypes.length }}
                {{ "translate.general.selected" | translate }})</span
              >
            </button>
          </input-wrap>

          <p-overlayPanel #op (onHide)="onTypesPanelHide()">
            <ng-template pTemplate>
              <p-tree
                [value]="catPhraseTypes"
                selectionMode="checkbox"
                [(selection)]="selectedTypeNodes"
                scrollHeight="250px"
                filter="true"
                propagateSelectionUp="false"
              ></p-tree>
            </ng-template>
          </p-overlayPanel>

          <!--match status
          <input-wrap
            *ngIf="showMatchSw"
            [loading]="matchSimilarityCodes && matchSimilarityCodes.loading"
          >
            <p-dropdown
              [options]="matchSimilarityCodes"
              optionLabel="label"
              [(ngModel)]="filterMatchStatus"
              [resetFilterOnHide]="true"
              [filterPlaceholder]="'translate.general.search' | translate"
              [placeholder]="'translate.cat.matchStatus' | translate"
              [filter]="true"
              (onChange)="onFieldValueChanged($event)"
            >
            </p-dropdown>
          </input-wrap>-->

          <!--status-->
          <input-wrap
            *ngIf="showMatchSw"
            [loading]="catPhraseStatusList && catPhraseStatusList.loading"
          >
            <p-dropdown
              [options]="catPhraseStatusList"
              optionLabel="label"
              [(ngModel)]="filterPhraseStatus"
              [resetFilterOnHide]="true"
              [filterPlaceholder]="'translate.general.search' | translate"
              [placeholder]="'translate.general.statusDescr' | translate"
              [filter]="true"
              (onChange)="onFieldValueChanged($event)"
            >
            </p-dropdown>
          </input-wrap>

          <!--content type-->
          <input-wrap [loading]="contentTypeList && contentTypeList.loading">
            <p-dropdown
              [options]="contentTypeList"
              optionLabel="label"
              [(ngModel)]="filterContentType"
              [resetFilterOnHide]="true"
              [filterPlaceholder]="'translate.general.search' | translate"
              [placeholder]="'translate.general.contentTypeDescr' | translate"
              [filter]="true"
              (onChange)="onFieldValueChanged($event)"
            >
            </p-dropdown>
          </input-wrap>

          <!--match type-->
          <input-wrap [loading]="matchTypeList && matchTypeList.loading">
            <p-dropdown
              [options]="matchTypeList"
              optionLabel="label"
              [(ngModel)]="filterMatchType"
              [resetFilterOnHide]="true"
              [filterPlaceholder]="'translate.general.search' | translate"
              [placeholder]="'translate.general.matchTypeDescr' | translate"
              [filter]="true"
              (onChange)="onFieldValueChanged($event)"
            >
            </p-dropdown>
          </input-wrap>

          <a *ngIf="displayClearFilter" (click)="clearFilter()">{{
            "translate.general.clear" | translate
          }}</a>

          <!-- Whitelist spelling issues -->
          &nbsp;

          <button
            class="t-button"
            [disabled]="loading"
            (click)="docSpellcheckWhitelistingOpenSw = true"
          >
            {{ "translate.general.spellingCheckIgnored" | translate }}
          </button>
        </div>

        <div class="l-align-center">
          <div *ngIf="editable" class="l-flex-column select-btns">
            <checkbox-wrap
              class="checkbox"
              [label]="'translate.general.selectAllPhrases' | translate"
            >
              <input
                type="checkbox"
                [(ngModel)]="toggleAllSw"
                (ngModelChange)="onToggleSelectAll()"
              />
            </checkbox-wrap>

            <checkbox-wrap
              class="checkbox"
              [label]="'translate.general.selectAllImages' | translate"
            >
              <input
                type="checkbox"
                [(ngModel)]="toggleAllImgSw"
                (ngModelChange)="onToggleSelectAllImg()"
              />
            </checkbox-wrap>
          </div>

          &nbsp; &nbsp;

          <button
            *ngIf="editable"
            class="t-button-outline"
            [disabled]="selectedElements.length == 0 || loading"
            (click)="approveSelected()"
          >
            {{ "translate.cat.approveSelected" | translate }}
            ({{ selectedElements.length }})
          </button>
          &nbsp;
          <button
            *ngIf="editable"
            class="t-button-outline"
            [disabled]="selectedElements.length == 0 || loading"
            (click)="rejectSelected()"
          >
            {{ "translate.cat.rejectSelected" | translate }}
            ({{ selectedElements.length }})
          </button>
          &nbsp;
          <button
            *ngIf="editable"
            class="t-button-outline"
            [disabled]="selectedPhrases.length == 0 || loading"
            (click)="editSelected()"
          >
            {{ "translate.cat.editSelected" | translate }}
            ({{ selectedPhrases.length }})
          </button>
          &nbsp;
          <button
            *ngIf="editable"
            class="t-button-outline"
            (click)="addPhrase()"
            [disabled]="!enableAddPhrase"
          >
            {{ "translate.cat.addPhrase" | translate }}
          </button>
          &nbsp;
          <button
            *ngIf="editable"
            class="t-button-outline"
            (click)="editPhraseOrderOpenSw = true"
            [disabled]="!enableAddPhrase"
          >
            {{ "translate.cat.editPhraseOrder" | translate }}
          </button>

          &nbsp;
          <button
            *ngIf="doc.inddS3Key"
            class="t-button-outline"
            (click)="downloadIndd()"
            [disabled]="downloadingZip"
          >
            <fa-icon [icon]="['fas', 'download']"></fa-icon>&nbsp; .indd
          </button>

          <div *ngIf="allowAddExtRef" class="actions">
            <button
              class="t-button"
              (click)="addExtRef()"
              [disabled]="selectedPhrases.length == 0"
            >
              {{ "translate.cat.selectAddRef" | translate }}
              ({{ selectedPhrases.length }})
            </button>
          </div>
        </div>
      </section>

      <div class="content-container">
        <div class="tbl-container">
          <cat-doc-phrase-list
            #phraseList
            (onTogglePhraseView)="onToggleElementView($event)"
            (onShowPhrase)="showAnnotation($event)"
            (onHidePhrase)="hideAnnotation($event)"
            [editable]="editable"
            [stateTag]="stateTag"
          ></cat-doc-phrase-list>
        </div>
        <div class="pdf-container">
          <pdf-viewer
            #docViewer
            [doc]="doc"
            [initialPage]="curPage"
            [singlePage]="true"
            [displayDrawMode]="displayDrawMode && !pageLoading"
            [displayToggleBoundingBoxes]="
              displayToggleBoundingBoxes && !pageLoading
            "
            [displayToggleAnnotations]="!pageLoading"
            [ignoreMouseEvents]="panelResizing"
            (onPageChanged)="onPageChanged($event)"
            (onToggleAnnotations)="onToggleAnnotations($event)"
            (onToggleImageAnnotations)="onToggleImageAnnotations($event)"
            (onIframeLoaded)="onDocIframeLoaded()"
            (onAnnMouseEnter)="onAnnMouseEnter($event)"
            (onAnnMouseLeave)="onAnnMouseLeave($event)"
            (onAnnClicked)="onAnnClicked($event)"
            (onBoundingBoxDraw)="onBoundingBoxDraw($event)"
            (onBoundingBoxClicked)="onBoundingBoxClicked($event)"
            (onToggleBoundingBoxes)="onToggleBoundingBoxes($event)"
          ></pdf-viewer>
        </div>
      </div>
    </tab-single>

    <tab-single
      [disabled]="!(docCards && docCards.length && docFrameLoaded)"
      tabTitle="{{ 'translate.general.cards' | translate }}  ({{
        docCards.length
      }})"
      [tabId]="tabIdList.CAT_DOC__CARDS"
    >
      <cat-doc-cards
        #catDocCards
        [curBox]="curBoundingBox"
        (onClose)="docCardsOpenSw = false"
        (onUpdate)="getDocCards()"
        [lcSw]="lcSw"
        [stateTag]="stateTag"
      >
      </cat-doc-cards>
    </tab-single>
  </tabs>
</div>

<cat-doc-phrase-details
  *ngIf="phraseDetailsOpenSw"
  (onClose)="phraseDetailsOpenSw = false"
  [mode]="'ind'"
  [editable]="editable"
  (onSave)="onPhraseSave()"
  [stateTag]="stateTag"
></cat-doc-phrase-details>

<cat-bounding-box
  *ngIf="boundingBoxOpenSw"
  [lcMode]="mode"
  [box]="curBoundingBox"
  (onClose)="boundingBoxOpenSw = false"
  (onSave)="onBoundingBoxSave($event)"
  (onDiscard)="onBoundingBoxDiscard()"
  (onDelete)="onBoundingBoxDelete()"
  (onImageSave)="onImageSave($event)"
>
</cat-bounding-box>

<cat-phrase-bulk-edit
  #phraseBulkEdit
  *ngIf="editPhraseTypeOpenSw"
  (onClose)="editPhraseTypeOpenSw = false"
  (onSave)="onBulkEditSave($event)"
>
</cat-phrase-bulk-edit>

<edit-phrase-order
  *ngIf="editPhraseOrderOpenSw"
  (onClose)="editPhraseOrderOpenSw = false"
  (onSave)="postPhraseOrderEdit()"
>
</edit-phrase-order>

<doc-spellcheck-whitelisting
  *ngIf="docSpellcheckWhitelistingOpenSw"
  (onClose)="docSpellcheckWhitelistingOpenSw = false"
  (onSave)="onUpdateSpellingWhitelist()"
  [docId]="doc.docId"
  [docMode]="'ind'"
>
</doc-spellcheck-whitelisting>
